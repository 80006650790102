import { Button, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showAlert } from "../../../../../slices/alertsSlice";
import { resetPasswordCurrentAssociate } from "../../../../../actions/associatesActions";

function UpdatePasswordForm() {
  const [state, setState] = useState({ password: "" });
  const [errorPassword, setErrorPassword] = useState("");

  const currentAssociate = useSelector((state) => state.investors.current);

  console.log({ currentAssociate });

  const dispatch = useDispatch();

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value?.trim() });
  };

  const handleSuccess = () => {
    dispatch(
      showAlert({
        type: "success",
        message: "Se ha actualizado la contraseña con exito",
      })
    );
  };

  useEffect(() => {
    setTimeout(() => {
      setErrorPassword("");
    }, 3000);
  }, [errorPassword]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (state.password === "" || state.password.length < 4) {
      setErrorPassword("Contraseña debe ser mayor a 4 caracteres");
      return;
    }
    dispatch(
      resetPasswordCurrentAssociate({
        id: currentAssociate._id,
        password: state.password,
        callback: () => {
          handleSuccess();
          setState({ password: "" });
        },
      })
    );
  };

  return (
    <Grid
      container
      component={"form"}
      onSubmit={handleSubmit}
      justifyContent={"center"}
      spacing={1}
    >
      <Grid item xs={12}>
        <TextField
          label="Nueva contraseña"
          type="text"
          name="password"
          value={state.password}
          onChange={handleChange}
          placeholder={`${currentAssociate?.user ?? "user"}1234`}
          helperText={
            !!errorPassword
              ? errorPassword
              : `Contraseña sugerida: ${currentAssociate?.user ?? "user"}1234`
          }
          error={!!errorPassword}
        />
      </Grid>
      <Grid item xs={6} sm={4} md={3}>
        <Button type="submit" fullWidth>
          Restablecer
        </Button>
      </Grid>
    </Grid>
  );
}

export default UpdatePasswordForm;
