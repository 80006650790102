import { Box, Card, CardContent, TextField } from "@mui/material";

import ProjectDetailsImagesCarousel from "./ProjectDetailsImagesCarousel";
// import ProjectDetailsDescriptionDialog from "./ProjectDetailsDescriptionDialog";

const styles = {
  inputWrapper: {
    margin: "0.5rem 1rem",
  },
  submit: {
    textAlign: "center",
  },
  actions: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
};

const ProjectDetailsDescription = (props) => {
  // const [open, setOpen] = useState(false);

  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  return (
    <>
      <Card>
        <CardContent>
          <ProjectDetailsImagesCarousel />
          <Box sx={styles.inputWrapper}>
            <TextField
              label="Nombre del proyecto"
              name="name"
              value={props.name}
              onChange={props.onChange}
            />
          </Box>
          <Box sx={styles.inputWrapper}>
            <TextField
              label="Alias del proyecto"
              name="alias"
              value={props.alias}
              onChange={props.onChange}
              fullWidth
            />
          </Box>
          <Box sx={styles.inputWrapper}>
            <TextField
              label="Vehículo de inversión"
              name="vehiculo"
              value={props.vehiculo}
              onChange={props.onChange}
            />
          </Box>
          <Box sx={styles.inputWrapper}>
            <TextField
              type="date"
              label="Fecha de inversión"
              name="investmentDate"
              value={props.investmentDate}
              onChange={props.onChange}
            />
          </Box>
          {/* <Box sx={styles.inputWrapper}>
            <Button fullWidth onClick={handleOpen}>
              Descripción del proyecto
            </Button>
          </Box> */}
        </CardContent>
      </Card>
      {/* <ProjectDetailsDescriptionDialog
        open={open}
        onClose={handleClose}
        description={props.description}
        image={props.descriptionImage}
      /> */}
    </>
  );
};

export default ProjectDetailsDescription;
