import { createAsyncThunk } from '@reduxjs/toolkit';
import { createLoan, deleteLoan, getLoan, getLoans, updateLoan } from '../api/loansApi';
import { showAlert } from '../slices/alertsSlice';
import { setCurrentLoan, setLoansList } from '../slices/loansSlice';
import { handleError } from '../slices/errorsSlice';
import { startLoading, endLoading } from '../slices/loadingSlice';

export const getLoansList = createAsyncThunk('loans/getLoansList', ({ query }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getLoans(token, query)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setLoansList({ loans: data.loans })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const getCurrentLoan = createAsyncThunk('loans/getCurrentLoan', ({ id }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getLoan(token, id)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentLoan({ loan: data.loan })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const createNewLoan = createAsyncThunk('loans/createNewLoan', ({ loan, callback }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  createLoan(token, loan)
  .then(response => response.status === 201 ? response.json() : Promise.reject(response))
  .then(data => callback(data.loan))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const updateCurrentLoan = createAsyncThunk('loans/updateCurrentLoan', ({ id, loan }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());
  
  updateLoan(token, id, loan)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(() => dispatch(showAlert({ message: 'Crédito actualizado', type: 'success' })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const deleteCurrentLoan = createAsyncThunk('loans/deleteCurrentLoan', ({ id, query }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());
  
  deleteLoan(token, id)
  .then(response => response.status === 200 ? true : Promise.reject(response))
  .then(() => dispatch(getLoansList({ query })))
  .then(() => dispatch(showAlert({ message: 'Crédito borrado', type: 'success' })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});
