const { REACT_APP_API_BASE_URL } = process.env;

export const createNotification = (token, notification) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/notifications', {
    method: 'POST',
    body: JSON.stringify({ notification }),
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const saveToken = (token, id, notification) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/token/' + id, {
    method: 'PUT',
    body: JSON.stringify({ notification }),
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

