import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: [],
  current: {}
}

const distributionsSlice = createSlice({
  name: 'distributions',
  initialState,
  reducers: {
    setDistributionsList: (state, { payload }) => ({ ...state, list: [...payload.distributions] }),
    setCurrentDistribution: (state, { payload }) => ({ ...state, current: { ...payload.distribution } }),
    resetDistributionsState: () => ({ list: [], current: { } })
  }
});

export const { setDistributionsList, setCurrentDistribution, resetDistributionsState } = distributionsSlice.actions;
export default distributionsSlice.reducer;
