import { orange } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

const defaultTheme = createTheme({
  status: {
    danger: orange[500],
  },
  palette: {
    primary: {
      main: "#081D2C",
      light: "#15485c",
      dark: "#4b869c",
      contrastText: "#fff",
    },
    secondary: {
      main: "#707070",
      light: "#4e4e4e",
      dark: "#8c8c8c",
      contrastText: "#fff",
    },
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
  },
});

const theme = createTheme({
  palette: { ...defaultTheme.palette },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          fontSize: "0.7rem",
        },
      },
      defaultProps: {
        fullWidth: true,
        variant: "standard",
        inputProps: {
          style: { fontSize: "0.7.5rem" },
        },
        InputLabelProps: {
          style: { fontSize: "0.8.5rem" },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "contained",
      },
      styleOverrides: {
        root: {
          marginTop: defaultTheme.spacing(1.5),
          marginBottom: defaultTheme.spacing(1.5),
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: defaultTheme.palette.primary.main,
        },
      },
    },
    MuiTableCell: {
      // defaultProps: {
      //   p: 1,
      // },
      styleOverrides: {
        root: {
          minWidth: "50px",
          maxWidth: "200px",
          paddingTop: "0.5rem",
          paddingBottom: "0.5rem",
          paddingRight: "0.5rem",
          paddingLeft: "0.5rem",
        },
        head: {
          color: defaultTheme.palette.primary.contrastText,
          fontWeight: "bold",
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          backgroundColor: defaultTheme.palette.primary.main,
          color: defaultTheme.palette.primary.contrastText,
          textAlign: "center",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          maxWidth: "95vw",
        },
      },
    },
  },
});

export default theme;
