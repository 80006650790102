const { REACT_APP_API_BASE_URL } = process.env;

export const getInvestments = (token, query) =>
  new Promise((resolve, reject) => {
    const queryParams = new URLSearchParams({
      results: 10,
      ...query,
    }).toString();
    const request = new Request(
      REACT_APP_API_BASE_URL + "/investments?" + queryParams,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      }
    );
    fetch(request)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });

export const getInvestment = (token, id) =>
  new Promise((resolve, reject) => {
    const request = new Request(REACT_APP_API_BASE_URL + "/investments/" + id, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    fetch(request)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });

export const createInvestment = (token, id, investment) =>
  new Promise((resolve, reject) => {
    const request = new Request(
      `${REACT_APP_API_BASE_URL}/associates/${id}/investments`,
      {
        method: "POST",
        body: JSON.stringify({ investment }),
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      }
    );
    fetch(request)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });

export const updateInvestment = (token, id, investment) =>
  new Promise((resolve, reject) => {
    const request = new Request(
      `${REACT_APP_API_BASE_URL}/associates/${id}/investments`,
      {
        method: "PUT",
        body: JSON.stringify({ investment }),
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      }
    );
    fetch(request)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });

export const deleteInvestment = (token, id) =>
  new Promise((resolve, reject) => {
    const request = new Request(`${REACT_APP_API_BASE_URL}/investments/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    fetch(request)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
