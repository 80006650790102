import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getInvestments,
  getInvestment,
  createInvestment,
  updateInvestment,
  deleteInvestment,
} from "../api/investmentsApi";
import {
  setInvestmentsList,
  setCurrentInvestment,
} from "../slices/investmentsSlice";
import { showAlert } from "../slices/alertsSlice";
import { handleError } from "../slices/errorsSlice";
import { startLoading, endLoading } from "../slices/loadingSlice";

export const getInvestmentsList = createAsyncThunk(
  "investments/getInvestmentsList",
  ({ query }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    getInvestments(token, query)
      .then((response) =>
        response.status === 200 ? response.json() : Promise.reject(response)
      )
      .then((data) =>
        dispatch(setInvestmentsList({ investments: data.investments }))
      )
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const getCurrentInvestment = createAsyncThunk(
  "investments/getCurrentInvestment",
  ({ id }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    getInvestment(token, id)
      .then((response) =>
        response.status === 200 ? response.json() : Promise.reject(response)
      )
      .then((data) =>
        dispatch(setCurrentInvestment({ investment: data.investment }))
      )
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const updateCurrentInvestment = createAsyncThunk(
  "investments/updateCurrentInvestment",
  ({ id, projectRef, investment }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    updateInvestment(token, id, { ...investment, projectRef })
      .then((response) =>
        response.status === 200 ? response.json() : Promise.reject(response)
      )
      .then(() =>
        dispatch(
          showAlert({ message: "Inversión actualizada", type: "success" })
        )
      )
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const createNewInvestment = createAsyncThunk(
  "investments/createNewInvestment",
  ({ id, investment, query, callback }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    createInvestment(token, id, investment)
      .then((response) =>
        response.status === 201 ? response.json() : Promise.reject(response)
      )
      .then(() => callback())
      .then(() => dispatch(getInvestmentsList({ query })))
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const deleteCurrentInvestment = createAsyncThunk(
  "investments/deleteCurrentInvestment",
  ({ id, callback }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    deleteInvestment(token, id)
      .then((response) =>
        response.status === 200 ? true : Promise.reject(response)
      )
      .then(() => callback())
      .then(() =>
        dispatch(
          showAlert({ message: "Inversionista borrado", type: "success" })
        )
      )
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);
