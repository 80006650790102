import { resetPasswordAssociateById } from "../associatesApi";

export const requestResetPassword = (token, id, password) =>
  new Promise((resolve, reject) => {
    resetPasswordAssociateById(token, id, password)
      .then((response) =>
        response.status === 200 ? response.json() : reject(response)
      )
      .then((data) => resolve(data));
  });
