import React, { useState } from "react";
import { Button, Card, Grid, TextField } from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { ExcelRenderer } from "react-excel-renderer";
import { useDispatch } from "react-redux";
import { showAlert } from "../../../../../../slices/alertsSlice";
import { showError } from "../../../../../../slices/errorsSlice";

function ImportExcelButton({ uploadHandler }) {
  const dispatch = useDispatch();

  const [file, setFile] = useState("");

  const onInputClick = (event) => {
    event.target.value = "";
  };

  const uploadFile = (event) => {
    let fileObj = event.target.files[0];

    if (fileObj == null) {
      dispatch(
        showError({
          errors: ["Documento no selecionado"],
        })
      );
      return;
    }
    setFile(fileObj.name);
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.error(err);
        dispatch(
          showError({
            errors: ["Error al leer documento"],
          })
        );
      } else {
        const { rows } = resp;
        const data = [...rows];
        data.shift();
        console.log(data);
        let dataIndex = -1;
        data.forEach((element, index) => {
          if (element.includes("#")) {
            dataIndex = index;
            return;
          }
        });
        let isDistribtion = false;
        data.forEach((element, index) => {
          if (
            element.includes("Distribucion Bruta") ||
            element.includes("Distribucion neta") ||
            element.includes("Distribucion")
          ) {
            isDistribtion = true;
            return;
          }
        });
        if (!isDistribtion) {
          dispatch(
            showAlert({
              message: "Documento con formato invalido, sin distribución",
              type: "error",
            })
          );
          dispatch(
            showError({
              errors: ["Documento con formato invalido, sin distribución"],
            })
          );
          setFile("");
          return;
        }

        if (dataIndex === -1) {
          dispatch(
            showAlert({
              message:
                "Documento con formato invalido, sin título del proyecto",
              type: "error",
            })
          );
          dispatch(
            showError({
              errors: [
                "Documento con formato invalido, sin título del proyecto",
              ],
            })
          );
          setFile("");
          return;
        }
        try {
          const excelData = data.slice(dataIndex + 1, data.length);
          const excelObject = excelData.map((col) => {
            let excelDate = !(col[3] === undefined)
              ? new Date(Date.UTC(0, 0, col[3]))
              : new Date();
            const newDta = excelDate - 1000 * 60 * 60 * 6 * 2;
            const realTime = new Date(newDta);
            return {
              name: col[1] ?? "",
              period: col[4] ?? "",
              date: realTime.toISOString().slice(0, 10),
              amount:
                col[5] === "-" || col[5] === undefined || col[5] == null
                  ? "0"
                  : Number(col[5]).toFixed(2).toString(),
              fees:
                col[6] === "-" || col[6] === undefined || col[6] == null
                  ? "0"
                  : Number(col[6]).toFixed(2).toString(),
              retentionTax:
                col[7] === "-" || col[7] === undefined || col[7] == null
                  ? "0"
                  : Number(col[7]).toFixed(2).toString(),
            };
          });
          uploadHandler(excelObject);
        } catch (error) {
          dispatch(
            showAlert({
              message: `Documento con formato invalido, ${error}`,
              type: "error",
            })
          );
          dispatch(
            showError({
              errors: [`Documento con formato invalido, ${error}`],
            })
          );
        }
      }
    });
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card elevation={2} sx={{ p: 2 }}>
          <Grid container justifyContent={"center"} spacing={2}>
            <Grid
              item
              xs={12}
              sm={6}
              md={9}
              display={"flex"}
              justifyContent={"center"}
              alignContent={"center"}
              alignItems={"center"}
            >
              <TextField
                fullWidth
                variant="outlined"
                value={file}
                disabled
              ></TextField>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              display={"flex"}
              justifyContent={"center"}
              alignContent={"center"}
              alignItems={"center"}
            >
              <Button
                fullWidth
                variant="contained"
                color="success"
                startIcon={<FileUploadIcon />}
                component="label"
                sx={{ my: 0 }}
              >
                Importar documento
                <input
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  type="file"
                  hidden
                  onClick={onInputClick}
                  onChange={uploadFile}
                />
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}

export default ImportExcelButton;
