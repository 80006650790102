import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";

import {
  getCurrentInvestor,
  updateCurrentInvestor,
} from "../../../../actions/investorsActions";
import { getInvestmentsList } from "../../../../actions/investmentsActions";
import { resetInvestorsState } from "../../../../slices/investorsSlice";
import InvestorDetailsCard from "../../Investors/InvestorDetails/InvestorDetailsCard";
import InvestorDetailsProjects from "../../Investors/InvestorDetails/InvestorDetailsProjects";
import InvestortChangePasswordSection from "./InvestorChangePassword/InvestortChangePasswordSection";

const styles = {
  columns: {
    px: "1rem",
  },
};

const AssociateByIdPage = () => {
  const params = useParams();

  const [investor, setInvestor] = useState({
    _id: "",
    name: "",
    email: "",
    phoneNumber: "",
    investor: "",
    other: "",
  });

  useEffect(() => {
    dispatch(getCurrentInvestor({ id: params.id }));
    dispatch(getInvestmentsList({ query: { associateRef: params.id } }));
    return () => dispatch(resetInvestorsState());
  }, []);

  const dispatch = useDispatch();
  const current = useSelector((state) => state.investors.current);

  useEffect(
    () =>
      setInvestor({
        ...investor,
        ...current,
        investmentDate: current.investmentDate?.slice(0, 10) ?? "",
      }),
    [current]
  );

  const handleChange = (e) => {
    setInvestor({ ...investor, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { name, email, phoneNumber, other } = investor;

    dispatch(
      updateCurrentInvestor({
        id: params.id,
        investor: {
          name,
          email,
          phoneNumber,
          investor: investor.investor,
          other,
        },
      })
    );
  };

  return (
    <>
      <Typography variant="h4" fontWeight="bold" sx={{ marginBottom: "1rem" }}>
        Inversionista {investor.name ?? params.id}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={7} sx={styles.columns}>
          <InvestorDetailsCard
            {...investor}
            investments={current}
            onChange={handleChange}
            onSubmit={handleSubmit}
          />
        </Grid>
        <Grid item xs={12} md={5} sx={styles.columns}>
          <InvestorDetailsProjects />
        </Grid>
        <Grid item xs={12} md={7} sx={styles.columns}>
          <InvestortChangePasswordSection />
        </Grid>
      </Grid>
    </>
  );
};

export default AssociateByIdPage;
