import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Close } from '@mui/icons-material';
import { Box, Dialog, DialogContent, DialogTitle, Button, DialogActions, Table, TableHead, TableRow, TableCell, TableBody, Typography, IconButton, Divider } from '@mui/material';
import ProjectDetailsInterestCreate from './ProjectDetailsInterestCreate';
import ProjectDetailsInterestItem from '../../../../components/items/ProjectDetailsInterestItem';

const styles = {
  container: {
    display: 'block',
  },
  content: {
    paddingY: 0,
  },
  actions: {
    paddingRight: '1rem'
  },
  title: {
    my: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  fieldWrapper: {
    px: '1rem'
  }
}

const ProjectDetailsInterests = props => {
  const interests = useSelector(state => state.interests.list);
  const [openCreate, setOpenCreate] = useState(false);

  const handleClose = e => {
    e?.preventDefault();
    props.onClose();
  }

  const handleOpenCreate = () => setOpenCreate(true);
  const handleCloseCreate = () => setOpenCreate(false);

  return (
    <>
      <Dialog
        open={props.open}
        onClose={handleClose}
      >
        <DialogTitle>
        <Box sx={styles.title}>
            <Typography variant="h5">Intereses</Typography>
            <IconButton onClick={handleClose}><Close /></IconButton>
          </Box>
          <Divider />
        </DialogTitle>
        <DialogContent sx={styles.content}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Fecha</TableCell>
                <TableCell>Periodo</TableCell>
                <TableCell>Monto</TableCell>
                <TableCell>Fees</TableCell>
                <TableCell>Distribución neta</TableCell>
                <TableCell>Pago en MXN</TableCell>
                <TableCell sx={{ borderRight: '2px solid #EEE' }}>Tipo de cambio</TableCell>
                <TableCell>Distribuciones acumuladas</TableCell>  
                <TableCell>Fees acumulados</TableCell>
                <TableCell>Retenciones acumuladas</TableCell>
                <TableCell>Acumulado Neto</TableCell>
                <TableCell>Guardar</TableCell>
                <TableCell>Borrar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {interests.map(interest => <ProjectDetailsInterestItem key={interest._id} {...interest} />)}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions sx={styles.actions}>
          <Button onClick={handleOpenCreate}>Añadir</Button>
        </DialogActions>
      </Dialog>
      <ProjectDetailsInterestCreate open={openCreate} onClose={handleCloseCreate} />
    </>
  );
}

export default ProjectDetailsInterests;
