import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Button,
  DialogActions,
  Typography,
} from "@mui/material";
import { useEffect } from "react";

const styles = {
  container: {
    display: "block",
  },
  content: {
    paddingY: 0,
  },
  actions: {
    paddingTop: 0,
    paddingRight: "1rem",
  },
};

const CompleteDialog = ({ onClose, open }) => {
  const handleClose = (e) => {
    onClose();
  };

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        onClose();
      }, 5000);
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{"Distribución masiva"}</DialogTitle>
      <DialogContent sx={styles.content}>
        <Box sx={styles.container}>
          <Typography>{"Distribuciones realizadas con exito"}</Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <Button type="button" variant="text" onClick={handleClose}>
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CompleteDialog;
