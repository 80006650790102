import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ProjectInvestorItem from "./ProjectInvestorItem";
import stringDiacriticInsensitive from "../../../../../../../utils/stringsDiagritics";
import CompleteDialog from "./CompleteDialog";
import { useNavigate } from "react-router-dom";

const styles = {
  container: {
    minWidth: "250px",
    minHeight: "150px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

function ProjectInvestors({ fileData }) {
  const navigate = useNavigate();
  const investments = useSelector((state) => state.investments.list);
  // const isLoading = useSelector((state) => state.loading.isLoading);

  // console.log({ investments, fileData });

  const [action, setAction] = useState(false);
  const [masterLoading, setMasterLoading] = useState(investments?.length ?? 0);
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false);

  const handleUpladDistribution = () => {
    setAction(true);
    setMasterLoading(investments?.length ?? 0);
    setLoading(true);
  };

  useEffect(() => {
    if (!loading) {
      return;
    }
    if (masterLoading <= 0) {
      setLoading(false);
      setAction(false);
      setComplete(true);
    }
  }, [masterLoading, loading]);

  const handleClose = () => {
    setComplete(false);
    navigate(-1);
  };

  return (
    <Box my={2}>
      <Dialog open={loading}>
        <DialogContent>
          <Box sx={styles.container}>
            <CircularProgress />
          </Box>
        </DialogContent>
      </Dialog>
      <CompleteDialog open={complete} onClose={handleClose} />
      <Grid container justifyContent={"end"}>
        <Grid item>
          <Button
            disabled={fileData.length < 1 || loading}
            onClick={handleUpladDistribution}
          >
            Crear distribuciones
          </Button>
        </Grid>
      </Grid>
      <Typography>Lista de inversionistas del proyecto</Typography>
      <Box>
        {investments?.map((investor, index) => (
          <Box key={investor?._id}>
            <ProjectInvestorItem
              action={action}
              setMasterLoading={setMasterLoading}
              investorItem={investor}
              index={index + 1}
              itemfileData={fileData.find((excelData) => {
                const comp1 = investor?.associateRef?.name === excelData.name;
                if (comp1) {
                  return true;
                }
                const comp2 =
                  investor?.associateRef?.name ===
                  stringDiacriticInsensitive(excelData.name);
                if (comp2) {
                  return true;
                }
                const comp3 =
                  stringDiacriticInsensitive(investor?.associateRef?.name) ===
                  excelData.name;
                if (comp3) {
                  return true;
                }
                const comp4 =
                  stringDiacriticInsensitive(investor?.associateRef?.name) ===
                  stringDiacriticInsensitive(excelData.name);
                if (comp4) {
                  return true;
                }
                const inName = investor?.associateRef?.name?.split(" ") ?? [""];
                const in2Name = inName[0] + " " + inName[1];
                const exName = excelData.name?.split(" ") ?? [""];
                const ex2Name = exName[0] + " " + exName[1];
                // console.log({ ex2Name, in2Name });
                const comp5 =
                  stringDiacriticInsensitive(in2Name) ===
                  stringDiacriticInsensitive(ex2Name);
                // console.log({ dat });
                if (comp5) {
                  return true;
                }
                return false;
              })}
              fileData={fileData}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default ProjectInvestors;
