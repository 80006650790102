import { useSelector } from "react-redux";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/Login/Login";
import Dashboard from "./pages/Dashboard/Dashboard";
import Projects from "./pages/Dashboard/Projects/Projects";
import ProjectDetails from "./pages/Dashboard/Projects/ProjectDetails/ProjectDetails";
// import Investors from "./pages/Dashboard/Investors/Investors";
import InvestorDetails from "./pages/Dashboard/Investors/InvestorDetails/InvestorDetails";
import InvestorProjectDetails from "./pages/Dashboard/Investors/InvestorDetails/InvestorProjectDetails/InvestorProjectDetails";
import Notifications from "./pages/Dashboard/Notifications/Notifications";
import InvestmentPage from "./pages/Dashboard/investments/InvesmentPage";
import AssociatePage from "./pages/Dashboard/associates/AssociatePage";
import AssociateByIdPage from "./pages/Dashboard/associates/ById/AssociateByIdPage";
import DistribitionExcelByProjectPage from "./pages/Dashboard/Projects/ProjectDetails/DistributionExcel/DistribitionExcelByProjectPage";
import ImportLastReportePage from "./pages/Dashboard/Projects/ProjectDetails/ImportLastReport/ImportLastReportePage";

const Router = () => {
  const token = useSelector((state) => state.auth.token);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/login"
          element={token ? <Navigate to="/dashboard" /> : <Login />}
        />
        <Route
          path="/dashboard/*"
          element={token ? <Dashboard /> : <Navigate to="/login" />}
        >
          <Route index element={<Navigate to="proyectos" />} />
          <Route path="proyectos" element={<Projects />} />
          <Route path="proyectos/:id" element={<ProjectDetails />} />
          <Route
            path="proyectos/:id/importar-distribuciones"
            element={<DistribitionExcelByProjectPage />}
          />
          <Route
            path="proyectos/:id/agregar-ultimo-reporte"
            element={<ImportLastReportePage />}
          />
          <Route path="inversionistas" element={<InvestmentPage />} />
          <Route path="asociados" element={<AssociatePage />} />
          <Route path="asociados/:id" element={<AssociateByIdPage />} />
          <Route
            path="asociados/:id/inversion/:investmentId"
            element={<InvestorProjectDetails />}
          />
          <Route path="inversionistas/:id" element={<InvestorDetails />} />
          <Route
            path="inversionistas/:id/inversion/:investmentId"
            element={<InvestorProjectDetails />}
          />
          <Route path="*" element={<Navigate to="proyectos" />} />
          <Route path="notificaciones" element={<Notifications />} />
        </Route>
        <Route
          path="*"
          element={
            token ? <Navigate to="/dashboard" /> : <Navigate to="/login" />
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
