import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Grid,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
} from "@mui/material";

import CurrencyField from "../../../components/inputs/CurrencyField";
import PercentageField from "../../../components/inputs/PercentageField";

import { createNewProject } from "../../../actions/projectsActions";

const defaultState = {
  name: "",
  vehiculo: "",
  investment: 0,
  investmentDate: new Date().toISOString().slice(0, 10),
  description: "",
  percentageIde: 0,
  contributions: 0,
  provisions: 0,
  performance: 0,
};

const styles = {
  container: {
    display: "block",
  },
  content: {
    paddingY: 0,
  },
  actions: {
    paddingTop: 0,
    paddingRight: "1rem",
    marginTop: "1rem",
  },
  inputWrapper: {
    paddingX: "0.25rem",
  },
};

const ProjectsCreateDialog = (props) => {
  const [project, setProject] = useState({ ...defaultState });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClose = (e) => {
    e.preventDefault();
    setProject({ ...defaultState });
    props.onClose();
  };

  const handleChange = (e) => {
    setProject({
      ...project,
      [e.target.name]: e.target.rawValue ?? e.target.value,
    });
  };

  const handleRedirect = (id) => {
    navigate("/dashboard/proyectos/" + id);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(createNewProject({ project, callback: handleRedirect }));
  };

  return (
    <Dialog open={props.open} onClose={handleClose}>
      <DialogTitle>Nuevo proyecto</DialogTitle>
      <DialogContent sx={styles.content}>
        <Grid container spacing={2}>
          <Grid item sx={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={styles.inputWrapper}>
                <TextField
                  label="Nombre"
                  name="name"
                  value={project.name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sx={styles.inputWrapper}>
                <TextField
                  label="Vehículo"
                  name="vehiculo"
                  value={project.vehiculo}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sx={styles.inputWrapper}>
                <CurrencyField
                  label="Inversión en el proyecto"
                  name="investment"
                  value={project.investment}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sx={styles.inputWrapper}>
                <TextField
                  type="date"
                  label="Fecha de inversión"
                  name="investmentDate"
                  value={project.investmentDate}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={styles.inputWrapper}>
                <PercentageField
                  label="Porcentaje de participación"
                  name="percentageIde"
                  value={project.percentageIde}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sx={styles.inputWrapper}>
                <TextField
                  label="Número de aportaciones"
                  name="contributions"
                  value={project.contributions}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sx={styles.inputWrapper}>
                <TextField
                  label="Número de disposiciones"
                  name="provisions"
                  value={project.provisions}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sx={styles.inputWrapper}>
                <PercentageField
                  label="Rendimiento anual esperado"
                  name="performance"
                  value={project.performance}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sx={styles.inputWrapper}>
            <TextField
              multiline
              rows={3}
              label="Descripción"
              name="description"
              value={project.description}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <Button variant="text" onClick={handleClose}>
          Cancelar
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProjectsCreateDialog;
