import { Box, Typography } from '@mui/material';

const styles = {
  footer: {
    px: '1rem',
    py: '1.7rem',
    textAlign: 'center',
    backgroundColor: 'secondary.main',
    color: 'secondary.contrastText'
  }
}

const AppFooter = () => {
  return (
    <Box component="footer" sx={styles.footer}>
      <Typography variant="body1" fontWeight="bold">Powered by IDE Milusos</Typography>
    </Box>
  );
}

export default AppFooter;
