import { Box, Button, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import InvestorsListTable from "./InvestorsListTable";
import InvestorsFilter from "../Investors/InvestorsFilter";
import { useEffect, useState } from "react";
import { resetInvestmentsState } from "../../../slices/investmentsSlice";
import { getInvestmentsList } from "../../../actions/investmentsActions";
import AppPagination from "../../../components/AppPagination";
import ProjectsCreateDialog from "./ProjectsCreateDialog";

const defaultFilter = {
  associate: "",
  project: "",
};

const Notifications = () => {
  const dispatch = useDispatch();
  const investors = useSelector((state) => state.investments.list);

  const [filter, setFilter] = useState({ ...defaultFilter });
  const [pagination, setPagination] = useState({ page: 1 });
  const [showCreate, setShowCreate] = useState(false);
  const [selectedInvestors, setSelectedInvestors] = useState([]);

  useEffect(() => {
    setPagination({ page: 1 });
    return () => dispatch(resetInvestmentsState());
  }, []);

  useEffect(() => {
    const f = { page: pagination.page };

    filter.associate.length > 0 ? (f.associate = filter.associate) : null;
    filter.project.length > 0 ? (f.project = filter.project) : null;

    dispatch(getInvestmentsList({ query: f }));
  }, [pagination]);

  const handleChange = (e) =>
    setFilter({ ...filter, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    setPagination({ page: 1 });
  };

  const onClear = () => {
    setFilter({ ...defaultFilter });
    setPagination({ page: 1 });
  };

  const nextPage = () => setPagination({ page: pagination.page + 1 });
  const prevPage = () =>
    setPagination({ page: Math.max(pagination.page - 1, 1) });

  const openCreate = () => setShowCreate(true);
  const closeCreate = () => setShowCreate(false);

  const handleCheckboxChange = (investor, checked) => {
    const id = selectedInvestors.find((i) => i === investor);
    if (id && !checked) {
      setSelectedInvestors(selectedInvestors.filter((i) => i !== investor));
    } else if (!id && checked) {
      setSelectedInvestors([...selectedInvestors, investor]);
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          variant="h5"
          fontWeight="bold"
          sx={{ marginBottom: "1rem" }}
        >
          Enviar notificación
        </Typography>
      </Box>
      <Grid container style={{ height: "580px", width: "100%" }}>
        <Grid item style={{ height: "380px", width: "100%" }}>
          <InvestorsFilter
            {...filter}
            onChange={handleChange}
            onSubmit={onSubmit}
            onClear={onClear}
          />
          <InvestorsListTable
            investors={investors}
            handleCheckboxChange={handleCheckboxChange}
          />
          <AppPagination
            page={pagination.page}
            prev={prevPage}
            next={nextPage}
          />
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              sx={{ marginTop: "0" }}
              color="primary"
              onClick={openCreate}
            >
              Escribir
            </Button>
          </Box>
          <ProjectsCreateDialog
            open={showCreate}
            onClose={closeCreate}
            selectedInvestors={selectedInvestors}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Notifications;
