import { Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell } from '@mui/material';
import ProjectsListItem from './ProjectsListItem';

const ProjectsListTable = props => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead color="primary">
          <TableRow>
            <TableCell>Nombre</TableCell>
            <TableCell>Fecha de inversión</TableCell>
            <TableCell>Divisa</TableCell>
            <TableCell>Inversión en el proyecto</TableCell>
            <TableCell>Participación</TableCell>
            <TableCell>Borrar</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.projects?.map(project => <ProjectsListItem key={project._id} {...project} onDelete={props.onDelete} />)}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ProjectsListTable;

