import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Check, Delete } from "@mui/icons-material";
import { TableRow, TableCell, IconButton, TextField } from "@mui/material";
import { useState } from "react";
import CurrencyField from "../inputs/CurrencyField";

import {
  deleteCurrentDistribution,
  updateCurrentDistribution,
} from "../../actions/distributionsActions";

const ProjectDetailsDistributionItem = (props) => {
  const dispatch = useDispatch();

  const params = useParams();

  const [data, setData] = useState({
    date: new Date(props.date).toISOString().slice(0, 10),
    period: props.period ?? "",
    amount: props.amount ?? 0,
    fees: props.fees ?? "",
    netDistribution: props.netDistribution ?? 0,
    mxn: props.mxn ?? 0,
    change: props.change ?? 0,
    accumulatedDistribution: props.accumulatedDistribution ?? 0,
    accumulatedFess: props.accumulatedFess ?? 0,
    retention: props.retention ?? 0,
    netAccumulated: props.netAccumulated ?? 0,
    retentionTax: props.retentionTax ?? 0,
  });

  const handleChange = (e) =>
    setData((prev) => ({
      ...prev,
      [e.target.name]: e.target.rawValue ?? e.target.value,
    }));

  const handleSave = (e) => {
    e?.preventDefault();
    dispatch(updateCurrentDistribution({ id: props._id, distribution: data }));
  };

  const handleDelete = (e) => {
    e?.preventDefault();
    dispatch(
      deleteCurrentDistribution({
        id: props._id,
        query: { projectRef: params.id },
      })
    );
  };

  return (
    <TableRow>
      <TableCell>
        <TextField
          type="date"
          label="Fecha"
          name="date"
          value={data.date}
          onChange={handleChange}
        />
      </TableCell>
      <TableCell>
        <TextField
          label="Periodo"
          name="period"
          value={data.period}
          onChange={handleChange}
        />
      </TableCell>
      <TableCell>
        <CurrencyField
          label="Monto"
          name="amount"
          value={data.amount}
          onChange={handleChange}
        />
      </TableCell>
      <TableCell>
        <CurrencyField
          label="Fees"
          name="fees"
          value={data.fees}
          onChange={handleChange}
        />
      </TableCell>
      <TableCell>
        <CurrencyField
          label="Retención de impuestos"
          name="retentionTax"
          value={data.retentionTax}
          onChange={handleChange}
        />
      </TableCell>
      <TableCell>
        <CurrencyField
          label="Distribución neta"
          name="netDistribution"
          value={data.netDistribution}
          onChange={handleChange}
        />
      </TableCell>
      <TableCell>
        <CurrencyField
          label="Pago en MXN"
          name="mxn"
          value={data.mxn}
          onChange={handleChange}
        />
      </TableCell>
      <TableCell sx={{ borderRight: "2px solid #EEE" }}>
        <CurrencyField
          label="Tipo de cambio"
          name="change"
          value={data.change}
          onChange={handleChange}
        />
      </TableCell>
      <TableCell>
        <CurrencyField
          label="Distribución acumulada"
          name="accumulatedDistribution"
          value={data.accumulatedDistribution}
          onChange={handleChange}
        />
      </TableCell>
      <TableCell>
        <CurrencyField
          label="Fees acumulados"
          name="accumulatedFess"
          value={data.accumulatedFess}
          onChange={handleChange}
        />
      </TableCell>
      <TableCell>
        <CurrencyField
          label="Retenciones acumuladas"
          name="retention"
          value={data.retention}
          onChange={handleChange}
        />
      </TableCell>
      <TableCell>
        <CurrencyField
          label="Acumulado neto"
          name="netAccumulated"
          value={data.netAccumulated}
          onChange={handleChange}
        />
      </TableCell>
      <TableCell>
        <IconButton onClick={handleSave}>
          <Check />
        </IconButton>
      </TableCell>
      <TableCell>
        <IconButton onClick={handleDelete}>
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default ProjectDetailsDistributionItem;
