const { REACT_APP_API_BASE_URL } = process.env;

export const getDistributions = (token, query) =>
  new Promise((resolve, reject) => {
    const queryParams = new URLSearchParams({
      ...query,
    }).toString();
    const request = new Request(
      REACT_APP_API_BASE_URL + "/distributions?" + queryParams,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      }
    );
    fetch(request)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });

export const getDistribution = (token, id) =>
  new Promise((resolve, reject) => {
    const request = new Request(
      REACT_APP_API_BASE_URL + "/distributions/" + id,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      }
    );
    fetch(request)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });

export const createDistribution = (token, projectId, distribution) =>
  new Promise((resolve, reject) => {
    const request = new Request(
      `${REACT_APP_API_BASE_URL}/projects/${projectId}/distributions`,
      {
        method: "POST",
        body: JSON.stringify({ distribution }),
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      }
    );
    fetch(request)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });

export const updateDistribution = (token, id, distribution) =>
  new Promise((resolve, reject) => {
    const request = new Request(
      REACT_APP_API_BASE_URL + "/distributions/" + id,
      {
        method: "PUT",
        body: JSON.stringify({ distribution }),
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      }
    );
    fetch(request)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });

export const deleteDistribution = (token, id) =>
  new Promise((resolve, reject) => {
    const request = new Request(
      REACT_APP_API_BASE_URL + "/distributions/" + id,
      {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      }
    );
    fetch(request)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
