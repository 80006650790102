import { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Close } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
  Typography,
  IconButton,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import CurrencyField from "../../../../components/inputs/CurrencyField";

import { createNewDistribution } from "../../../../actions/distributionsActions";

const defaultState = {
  date: new Date().toISOString().slice(0, 10),
  period: "",
  amount: "",
  retentionTax: "",
  fees: "",
  netDistribution: "",
  mxn: "",
  change: "",
  accumulatedDistribution: "",
  accumulatedFess: "",
  retention: "",
  netAccumulated: "",
};

const styles = {
  container: {
    display: "block",
  },
  content: {
    paddingY: 0,
  },
  actions: {
    paddingRight: "1rem",
  },
  title: {
    my: "1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  fieldWrapper: {
    px: "1rem",
  },
};

const ProjectDetailsDistributionCreate = (props) => {
  let { investmentId } = useParams();
  const [distribution, setDistribution] = useState({ ...defaultState });

  const dispatch = useDispatch();
  const params = useParams();

  const handleChange = (e) =>
    setDistribution({
      ...distribution,
      [e.target.name]: e.target.rawValue ?? e.target.value,
    });

  const handleClose = (e) => {
    e?.preventDefault();
    setDistribution({ ...defaultState });
    props.onClose();
  };

  const handleSubmit = (e) => {
    dispatch(
      createNewDistribution({
        projectId: params.id,
        distribution,
        query: { projectRef: params.id, associateInvestmentId: investmentId },
        callback: handleClose,
      })
    );
  };

  return (
    <Dialog open={props.open} onClose={handleClose}>
      <DialogTitle>
        <Box sx={styles.title}>
          <Typography variant="h5">Crear distribución</Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </DialogTitle>
      <DialogContent sx={styles.content}>
        <Grid container>
          <TextField
            type="date"
            label="Fecha"
            name="date"
            value={distribution.date}
            onChange={handleChange}
          />
          <TextField
            label="Periodo"
            name="period"
            value={distribution.period}
            onChange={handleChange}
          />
          <CurrencyField
            label="Monto"
            name="amount"
            value={distribution.amount}
            onChange={handleChange}
          />
          <CurrencyField
            label="Fees"
            name="fees"
            value={distribution.fees}
            onChange={handleChange}
          />
          <CurrencyField
            label="Retención de impuestos"
            name="retentionTax"
            value={distribution.retentionTax}
            onChange={handleChange}
          />
          <CurrencyField
            label="Distribución neta"
            name="netDistribution"
            value={distribution.netDistribution}
            onChange={handleChange}
          />
          <CurrencyField
            label="Pago en MXN"
            name="mxn"
            value={distribution.mxn}
            onChange={handleChange}
          />
          <CurrencyField
            label="Tipo de cambio"
            name="change"
            value={distribution.change}
            onChange={handleChange}
          />{" "}
          <CurrencyField
            label="Distribución acumulada"
            name="accumulatedDistribution"
            value={distribution.accumulatedDistribution}
            onChange={handleChange}
          />
          <CurrencyField
            label="Fees acumulados"
            name="accumulatedFess"
            value={distribution.accumulatedFess}
            onChange={handleChange}
          />
          <CurrencyField
            label="Retenciones"
            name="retention"
            value={distribution.retention}
            onChange={handleChange}
          />
          <CurrencyField
            label="Neto acumulado"
            name="netAccumulated"
            value={distribution.netAccumulated}
            onChange={handleChange}
          />
        </Grid>
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <Button onClick={handleSubmit}>Guardar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProjectDetailsDistributionCreate;
