import { Card, CardContent, CardHeader } from "@mui/material";
import React from "react";
import UpdatePasswordForm from "./UpdatePasswordForm";

function InvestortChangePasswordSection() {
  return (
    <Card>
      <CardHeader title="Recuperar contraseña" />
      <CardContent>
        <UpdatePasswordForm />
      </CardContent>
    </Card>
  );
}

export default InvestortChangePasswordSection;
