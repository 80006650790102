import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, Typography } from '@mui/material';
import ProjectsListTable from './ProjectsListTable';
import ProjectsFilter from './ProjectsFilter';
import ProjectsCreateDialog from './ProjectsCreateDialog';
import AppPagination from '../../../components/AppPagination';

import { getProjectsList } from '../../../actions/projectsActions';
import { resetProjectsState } from '../../../slices/projectsSlice';

const defaultFilter = {
  page: 1,
  name: '',
};

const Projects = () => {
  const dispatch = useDispatch();
  const projects = useSelector(state => state.projects.list);
  
  const [filter, setFilter] = useState({ ...defaultFilter });
  const [pagination, setPagination] = useState({ page: 1 });
  const [showCreate, setShowCreate] = useState(false);

  useEffect(() => {
    setPagination({ page: 1 });
    return () => dispatch(resetProjectsState());
  }, []);

  useEffect(() => {
    const f = { page: pagination.page };

    filter.name.length > 0 ? f.name = filter.name : null;

    dispatch(getProjectsList({ query: f }));
  }, [pagination]);

  const handleChange = e => setFilter({ ...filter, [e.target.name]: e.target.value });

  const onSubmit = e => {
    e.preventDefault();
    setPagination({ page: 1 });
  }

  const onClear = () => {
    setFilter({ ...defaultFilter });
    setPagination({ page: 1 });
  }

  const nextPage = () => setPagination({ page: pagination.page + 1 });
  const prevPage = () => setPagination({ page: Math.max(pagination.page - 1, 1) });

  const openCreate = () => setShowCreate(true);
  const closeCreate = () => setShowCreate(false);

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h5" fontWeight="bold" sx={{ marginBottom: '1rem' }}>Proyectos</Typography>
        <Button sx={{ marginTop: '0' }} color="primary" onClick={openCreate}>Nuevo</Button>
      </Box>
      <ProjectsFilter {...filter} onChange={handleChange} onSubmit={onSubmit} onClear={onClear} />
      <ProjectsListTable projects={projects} onDelete={onClear} />
      <AppPagination page={pagination.page} prev={prevPage} next={nextPage} />
      <ProjectsCreateDialog open={showCreate} onClose={closeCreate} />
    </>
  );
}

export default Projects;
