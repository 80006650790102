import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createReport,
  deleteReport,
  getReport,
  getReports,
  updateReport,
  uploadReportFile,
} from "../api/reportsApi";
import { showAlert } from "../slices/alertsSlice";
import { setCurrentReport, setReportsList } from "../slices/reportsSlice";
import { handleError } from "../slices/errorsSlice";
import { startLoading, endLoading } from "../slices/loadingSlice";

export const getLocalReportsList = createAsyncThunk(
  "reports/getReportsList",
  ({ query }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    getReports(token, query)
      .then((response) =>
        response.status === 200 ? response.json() : Promise.reject(response)
      )
      .then((data) => Promise.response(data.reports))
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const getReportsList = createAsyncThunk(
  "reports/getReportsList",
  ({ query }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    getReports(token, query)
      .then((response) =>
        response.status === 200 ? response.json() : Promise.reject(response)
      )
      .then((data) => dispatch(setReportsList({ reports: data.reports })))
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const getCurrentReport = createAsyncThunk(
  "reports/getCurrentReport",
  ({ id }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    getReport(token, id)
      .then((response) =>
        response.status === 200 ? response.json() : Promise.reject(response)
      )
      .then((data) => dispatch(setCurrentReport({ report: data.report })))
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const createNewReport = createAsyncThunk(
  "reports/createNewReport",
  ({ report, file, query, callback }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    createReport(token, report)
      .then((response) =>
        response.status === 201 ? response.json() : Promise.reject(response)
      )
      .then((data) => uploadReportFile(token, data.report_id, file))
      .then((response) =>
        response.status === 200 ? response.json() : Promise.reject(response)
      )
      .then((data) => callback(data.distribution))
      .then(() => dispatch(getReportsList({ query })))
      .then(() =>
        dispatch(showAlert({ message: "Reporte creado", type: "success" }))
      )
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const updateCurrentReport = createAsyncThunk(
  "reports/updateCurrentReport",
  ({ id, report }, { getState, dispatch }) => {
    const { token } = getState().auth;
    dispatch(startLoading());

    updateReport(token, id, report)
      .then((response) =>
        response.status === 200 ? response.json() : Promise.reject(response)
      )
      .then((data) => dispatch(setCurrentReport({ report: data.report })))
      .then(() =>
        dispatch(
          showAlert({ message: "Proyecto actualizado", type: "success" })
        )
      )
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const deleteCurrentReport = createAsyncThunk(
  "reports/deleteCurrentReport",
  ({ id, query, callback }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    deleteReport(token, id)
      .then((response) =>
        response.status === 200 ? true : Promise.reject(response)
      )
      .then(() => dispatch(getReportsList({ query })))
      .then(() =>
        dispatch(showAlert({ message: "Reporte borrado", type: "success" }))
      )
      .then(() => {
        callback();
      })
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);
