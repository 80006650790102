const { REACT_APP_API_BASE_URL } = process.env;

export const getReports = (token, query) =>
  new Promise((resolve, reject) => {
    const queryParams = new URLSearchParams({
      ...query,
    }).toString();
    const request = new Request(
      REACT_APP_API_BASE_URL + "/reports?" + queryParams,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      }
    );
    fetch(request)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });

export const getReport = (token, id) =>
  new Promise((resolve, reject) => {
    const request = new Request(REACT_APP_API_BASE_URL + "/reports/" + id, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    fetch(request)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });

export const createReport = (token, report) =>
  new Promise((resolve, reject) => {
    const request = new Request(REACT_APP_API_BASE_URL + "/reports", {
      method: "POST",
      body: JSON.stringify({ report }),
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    fetch(request)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });

export const updateReport = (token, id, report) =>
  new Promise((resolve, reject) => {
    const request = new Request(REACT_APP_API_BASE_URL + "/reports/" + id, {
      method: "PUT",
      body: JSON.stringify({ report }),
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    fetch(request)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });

export const uploadReportFile = (token, id, file) =>
  new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("report", file);

    const request = new Request(
      REACT_APP_API_BASE_URL + "/reports/" + id + "/file",
      {
        method: "PUT",
        body: formData,
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    fetch(request)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });

export const deleteReport = (token, id) =>
  new Promise((resolve, reject) => {
    const request = new Request(REACT_APP_API_BASE_URL + "/reports/" + id, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    fetch(request)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
