import { createAsyncThunk } from '@reduxjs/toolkit';
import { createFee, deleteFee, getFee, getFees, updateFee } from '../api/feesApi';
import { showAlert } from '../slices/alertsSlice';
import { setCurrentFee, setFeesList } from '../slices/feesSlice';
import { handleError } from '../slices/errorsSlice';
import { startLoading, endLoading } from '../slices/loadingSlice';

export const getFeesList = createAsyncThunk('fees/getFeesList', ({ query }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getFees(token, query)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setFeesList({ fees: data.fees_id })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const getCurrentFee = createAsyncThunk('fees/getCurrentFee', ({ id }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getFee(token, id)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentFee({ fee: data.fee })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const createNewFee = createAsyncThunk('fees/createNewFee', ({ fee, callback }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  createFee(token, fee)
  .then(response => response.status === 201 ? response.json() : Promise.reject(response))
  .then(data => callback(data.fee))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const updateCurrentFee = createAsyncThunk('fees/updateCurrentFee', ({ id, fee }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());
  
  updateFee(token, id, fee)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(() => dispatch(showAlert({ message: 'Fee actualizada', type: 'success' })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const deleteCurrentFee = createAsyncThunk('fees/deleteCurrentFee', ({ id, query }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());
  
  deleteFee(token, id)
  .then(response => response.status === 200 ? true : Promise.reject(response))
  .then(() => dispatch(getFeesList({ query })))
  .then(() => dispatch(showAlert({ message: 'Fee borrada', type: 'success' })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});
