import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Close } from '@mui/icons-material';
import { Box, Dialog, DialogContent, DialogTitle, Button, DialogActions, Typography, IconButton, Divider, Grid, TextField, MenuItem } from '@mui/material';
import CurrencyField from '../../../../components/inputs/CurrencyField';
import PercentageField from '../../../../components/inputs/PercentageField';

import { createNewFee, getFeesList } from '../../../../actions/feesActions';

const defaultState = {
  name: '',
  value: '',
  applyType: '',
  type: '',
};

const styles = {
  container: {
    display: 'block',
  },
  content: {
    paddingY: 0,
  },
  actions: {
    paddingRight: '1rem'
  },
  title: {
    my: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  fieldWrapper: {
    px: '1rem'
  }
}

const ProjectDetailsFeeCreate = props => {
  const [fee, setFee] = useState({ ...defaultState });

  const dispatch = useDispatch();
  const params = useParams();

  const handleChange = e => setFee({ ...fee, [e.target.name]: e.target.rawValue ?? e.target.value });

  const handleClose = e => {
    e?.preventDefault();
    setFee({ ...defaultState });
    props.onClose();
  }

  const handleSubmit = e => {
    dispatch(createNewFee({ fee: { ...fee, projectRef: params.id }, callback: () => {
      handleClose();
      dispatch(getFeesList({ query: { projectRef: params.id } }));
    } }));
  }

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
    >
      <DialogTitle>
       <Box sx={styles.title}>
          <Typography variant="h5">Crear fee</Typography>
          <IconButton onClick={handleClose}><Close /></IconButton>
        </Box>
        <Divider />
      </DialogTitle>
      <DialogContent sx={styles.content}>
        <Grid container>
          <TextField label="Nombre" name="name" value={fee.name} onChange={handleChange} />
          <TextField select label="Tipo" name="type" value={fee.type} onChange={handleChange}>
            <MenuItem value="distribution">Distribución</MenuItem>
            <MenuItem value="payment">Interés</MenuItem>
          </TextField>
          <TextField select label="Aplicación" name="applyType" value={fee.applyType} onChange={handleChange}>
            <MenuItem value="monto">Monto</MenuItem>
            <MenuItem value="porcentaje">Porcentaje</MenuItem>
          </TextField>
          {fee.applyType === 'monto'
            ? <CurrencyField label="Valor" name="value" value={fee.value} onChange={handleChange} />
            : <PercentageField label="Valor" name="value" value={fee.value} onChange={handleChange} />
          }
        </Grid>
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <Button onClick={handleSubmit}>Guardar</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ProjectDetailsFeeCreate;
