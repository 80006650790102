import { createAsyncThunk } from '@reduxjs/toolkit';
import { getInterests, updateInterest, deleteInterest } from '../api/interestsApi';
import { setInterestsList } from '../slices/interestsSlice';
import { showAlert } from '../slices/alertsSlice';
import { handleError } from '../slices/errorsSlice';
import { startLoading, endLoading } from '../slices/loadingSlice';

export const getInterestsList = createAsyncThunk('interests/getInterestsList', ({ query }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getInterests(token, query)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setInterestsList({ interests: data.payments })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const updateCurrentInterest = createAsyncThunk('interests/updateCurrentInterest', ({ id, interest }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());
  
  updateInterest(token, id, interest)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(() => dispatch(showAlert({ message: 'Distribución actualizado', type: 'success' })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const deleteCurrentInterest = createAsyncThunk('interests/deleteCurrentInterest', ({ id, query }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());
  
  deleteInterest(token, id)
  .then(response => response.status === 200 ? true : Promise.reject(response))
  .then(() => dispatch(getInterestsList({ query })))
  .then(() => dispatch(showAlert({ message: 'Distribución borrada', type: 'success' })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});
