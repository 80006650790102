import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AttachFile, Close, DeleteForever } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  IconButton,
  Divider,
  Grid,
  TextField,
  // Select,
  // MenuItem,
} from "@mui/material";

import {
  createNewReport,
  deleteCurrentReport,
  updateCurrentReport,
} from "../../../../../actions/reportsActions";
// import ReportItem from "../../../../../components/items/ReportItem";
import { useParams } from "react-router-dom";
import { grey } from "@mui/material/colors";

const styles = {
  container: {
    display: "block",
  },
  content: {
    paddingY: 0,
    width: {
      md: "60vw",
    },
  },
  actions: {
    paddingRight: "1rem",
  },
  title: {
    my: "1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  fieldWrapper: {
    px: "1rem",
  },
};

const InvestorProjectDetailsReports = ({
  onClose,
  open,
  myReports,
  distributionId,
  localPeriod,
  localDate,
}) => {
  const dispatch = useDispatch();
  let { investmentId } = useParams();
  const reportsFromState = useSelector((state) => state.reports.list);
  const current = useSelector((state) => state.investments.current);
  // const distributions = useSelector((state) => state.distributions.list);
  // const interests = useSelector((state) => state.interests.list);
  const [reports, setReports] = useState([]);
  const [distribution, setDistribution] = useState("");
  const [selectedReport, setSelectedReport] = useState(null);
  const [selectedPathReport, setSelectedPathReport] = useState(null);
  const [file, setFile] = useState(null);
  const [report, setReport] = useState({
    name: "",
    period: localPeriod ?? "",
    date:
      localDate ??
      new Date(myReports?.date ?? new Date()).toISOString().slice(0, 10),
    type: "financiero",
  });

  useEffect(() => {
    setDistribution(distributionId);
    if (myReports === undefined || myReports == null) {
      return;
    }
    setSelectedReport(myReports);
    setSelectedPathReport(myReports?.path);
    setDistribution(myReports.distributionRef);
    setReport({
      name: myReports.name,
      period: myReports.period,
      date: localDate ?? new Date().toISOString().slice(0, 10),
      distributionRef: myReports.distributionRef,
      type: "informativo",
    });
  }, [myReports, distributionId]);

  useEffect(() => {
    if (reportsFromState) {
      setReports(reportsFromState);
    }
  }, [reportsFromState]);

  const handleChange = (e) =>
    setReport({ ...report, [e.target.name]: e.target.value });

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setReport({
      ...report,
      name: e.target.files[0].name,
    });
  };

  const handleClose = (e) => {
    e?.preventDefault();
    onClose();
  };

  // const handleEditReport = (report) => {
  //   setSelectedReport(report);
  //   setDistribution(report.distributionRef);
  //   setReport({
  //     name: report.name,
  //     period: report.period,
  //     date: new Date(report.date).toISOString().slice(0, 10),
  //     distributionRef: report.distributionRef,
  //     type: "informativo",
  //   });
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedPathReport) {
      dispatch(
        updateCurrentReport({
          id: selectedReport._id,
          report: { ...report, distributionRef: distribution },
        })
      );

      setReports(
        reports.map((r) => {
          if (r._id === selectedReport._id) {
            return {
              ...report,
              _id: selectedReport._id,
              distributionRef: distribution,
            };
          }
          return r;
        })
      );
    } else {
      if (distribution) {
        /* console.log({
          report: { ...report, distributionRef: distribution , projectRef: current[0]?.projectRef?._id, associateRef: current[0]?.associateRef?._id, date: new Date(report.date).toISOString().replace("T00","T06") },
          file,
          query: { projectRef: current[0]?.projectRef?._id, associateRef: current[0]?.associateRef?._id }
        }) */

        dispatch(
          createNewReport({
            report: {
              ...report,
              distributionRef: distribution,
              projectRef: current[0]?.projectRef?._id,
              associateRef: current[0]?.associateRef?._id,
              date: new Date(report.date).toISOString().replace("T00", "T06"),
              investmentRef: investmentId,
            },
            file,
            query: {
              projectRef: current[0]?.projectRef?._id,
              associateRef: current[0]?.associateRef?._id,
              associateInvestmentId: investmentId,
            },
            callback: handleClose,
          })
        );
      } else {
        /* console.log({
          report: { ...report, projectRef: current[0]?.projectRef?._id, associateRef: current[0]?.associateRef?._id, date: new Date(report.date).toISOString().replace("T00","T06") },
          file,
          query: { projectRef: current[0]?.projectRef?._id, associateRef: current[0]?.associateRef?._id }
        }) */

        dispatch(
          createNewReport({
            report: {
              ...report,
              projectRef: current[0]?.projectRef?._id,
              associateRef: current[0]?.associateRef?._id,
              date: new Date(report.date).toISOString().replace("T00", "T06"),
              investmentRef: investmentId,
            },
            file,
            query: {
              projectRef: current[0]?.projectRef?._id,
              associateRef: current[0]?.associateRef?._id,
              associateInvestmentId: investmentId,
            },
            callback: handleClose,
          })
        );
      }
    }
    setDistribution("");
    setFile(null);
    setSelectedReport(null);
    setReport({
      name: "",
      period: "",
      date: new Date().toISOString().slice(0, 10),
      type: "informativo",
    });
  };

  const successDeleteReport = () => {
    setFile(null);
    setSelectedPathReport(null);
    setReport({
      ...report,
      name: "",
    });
  };

  const handleDelete = (e) => {
    e?.preventDefault();
    const query = { projectRef: selectedReport.projectRef };
    selectedReport.associateRef
      ? (query.associateRef = selectedReport.associateRef)
      : null;
    dispatch(
      deleteCurrentReport({
        id: selectedReport._id,
        query: { ...query, associateInvestmentId: investmentId },
        callback: successDeleteReport,
      })
    );
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Box sx={styles.title}>
          <Typography variant="h5">Reportes informativos</Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </DialogTitle>
      <DialogContent sx={styles.content}>
        <Grid container>
          <Grid item xs={12} md={12} sx={{ px: "1rem" }}>
            <Typography variant="h6">Nuevo reporte</Typography>
            <Grid container columnSpacing={3}>
              <Grid item xs={6}>
                <TextField
                  label="Nombre"
                  name="name"
                  disabled
                  value={report.name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ textAlign: "center" }}>
                  {selectedPathReport ?? false ? (
                    <Box display={"flex"}>
                      <Box
                        flex={1}
                        display={"flex"}
                        alignContent={"center"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <Typography
                          color={grey}
                          component={"p"}
                          variant="body2"
                        >
                          {selectedPathReport ??
                            "Sin documento, borrar y subir nuevo"}
                        </Typography>
                      </Box>
                      <Box>
                        <IconButton onClick={handleDelete}>
                          <DeleteForever></DeleteForever>
                        </IconButton>
                      </Box>
                    </Box>
                  ) : (
                    <>
                      <Button variant="text" component="label">
                        <input hidden type="file" onChange={handleFileChange} />
                        <AttachFile />
                      </Button>
                      <Typography variant="caption">
                        {file ? file.name : "Seleccionar archivo"}
                      </Typography>
                    </>
                  )}
                </Box>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Periodo"
                  name="period"
                  disabled
                  value={report.period}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="date"
                  label="Fecha"
                  name="date"
                  disabled
                  value={report.date}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            {/* 
            <Select
              labelId="distribución"
              id="year-select"
              disabled
              value={distribution}
              label="Distribución"
              onChange={(e) => setDistribution(e.target.value)}
              sx={{
                marginTop: "1em",
                marginBottom: "1em",
                width: "100%",
                p: 0,
              }}
            >
              {distributions &&
                interests &&
                [...distributions, ...interests].map((d) => {
                  return (
                    <MenuItem key={d._id} value={d._id}>
                      (
                      {d.paymentType === "Associate-Payment"
                        ? "Interes"
                        : "Distribución"}
                      ) Fecha: {new Date(d.date).toDateString()} - Periodo:{" "}
                      {d.period}
                    </MenuItem>
                  );
                })}
            </Select> */}

            <Box sx={{ textAlign: "center" }}>
              <Button
                disabled={
                  !selectedReport &&
                  (!report.name || !report.period || !report.date || !file)
                }
                onClick={handleSubmit}
              >
                Guardar
              </Button>
              {/* <div style={{ display: "inline-block", margin: "0 1em" }}></div>
              <Button
                onClick={() => {
                  setSelectedReport(null);
                  setDistribution("");
                  setFile(null);
                  setReport({
                    name: "",
                    period: "",
                    date: new Date().toISOString().slice(0, 10),
                    type: "informativo",
                  });
                }}
              >
                Limpiar
              </Button> */}
            </Box>
          </Grid>
          {/* <Grid item xs={12} md={6} sx={{ px: "1rem" }}>
            <Typography variant="h6">Reportes informativos</Typography>
            {reports.map((report) => (
              <ReportItem
                key={report._id}
                {...report}
                report={report}
                handleEditReport={handleEditReport}
              />
            ))}
          </Grid> */}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default InvestorProjectDetailsReports;
