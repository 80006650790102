import { TableRow, TableCell,  Checkbox } from '@mui/material';

const label = { inputProps: { 'aria-label': 'Checkbox' } };


const InvestorsListItem = props => {
  return (
    <TableRow>
      <TableCell><Checkbox {...label} onChange={e => props.handleCheckboxChange(e.target.checked)} /> {props.investor || props.associateRef?.investor || props.associateRef?.name}</TableCell>
      <TableCell>{props.associateRef?.name}</TableCell>
      <TableCell>{props.projectRef?.name}</TableCell>
    </TableRow>
  );
}

export default InvestorsListItem;
