import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

const styles = {
  inputWrapper: {
    margin: "0.5rem 1rem",
  },
  submit: {
    textAlign: "center",
  },
  actions: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
};

const InvestorDetailsCard = (props) => {
  return (
    <Card>
      <CardHeader title="Datos del inversionista" />
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h5">Info de contacto</Typography>
            <Box sx={styles.inputWrapper}>
              <TextField
                label="Nombre"
                name="name"
                value={props.name}
                onChange={props.onChange}
              />
            </Box>
            <Box sx={styles.inputWrapper}>
              <TextField
                label="Correo"
                name="email"
                value={props.email}
                onChange={props.onChange}
              />
            </Box>
            <Box sx={styles.inputWrapper}>
              <TextField
                label="Teléfono"
                name="phoneNumber"
                value={props.phoneNumber}
                onChange={props.onChange}
              />
            </Box>
            <Box sx={styles.inputWrapper}>
              <TextField
                label="Otros"
                name="other"
                multiline
                rows={3}
                value={props.other}
                onChange={props.onChange}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={styles.submit}>
              <Button onClick={props.onSubmit}>Guardar</Button>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default InvestorDetailsCard;
