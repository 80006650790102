import {
  Card,
  CircularProgress,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDistributions } from "../../../../../../../api/distributionsApi";
import { handleError } from "../../../../../../../slices/errorsSlice";
import { Cancel, CheckCircle } from "@mui/icons-material";
import HelpIcon from "@mui/icons-material/Help";
import CurrencyField from "../../../../../../../components/inputs/CurrencyField";
import { createNewDistributionByInvestor } from "../../../../../../../actions/investorsActions";
import { Link } from "react-router-dom";

const defaultDistribition = {
  date: new Date().toISOString().slice(0, 10),
  period: "",
  amount: "0",
  retentionTax: "0",
  fees: "0",
  netDistribution: "0",
  mxn: "0",
  change: "0",
  accumulatedDistribution: "0",
  accumulatedFess: "0",
  retention: "0",
  netAccumulated: "0",
};

const locaDefaultDistribition = {
  amount: "0",
  retentionTax: "0",
  fees: "0",
};

// const defaultReport = {
//   name: "",
//   period: "",
//   date: new Date().toISOString().slice(0, 10),
//   type: "financiero",
// };

function ProjectInvestorItem({
  action,
  investorItem,
  index,
  itemfileData,
  setMasterLoading,
  fileData,
}) {
  const [loading, setLoading] = useState(false);
  const [lastDistributionsList, setLastDistributionsList] = useState([]);
  const [localFile, setLocalFile] = useState(itemfileData);
  const [isReady, setReady] = useState(false);
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const [distribution, setDistribution] = useState({ ...defaultDistribition });
  const [myDistribution, setMyDistribution] = useState({
    ...locaDefaultDistribition,
  });

  useEffect(() => {
    const fetchData = () => {
      const associateRef = investorItem.associateRef._id;
      const projectRef = investorItem.projectRef._id;
      getDistributions(token, {
        associateRef,
        projectRef,
        associateInvestmentId: investorItem._id,
      })
        .then((response) =>
          response.status === 200 ? response.json() : Promise.reject(response)
        )
        .then((data) => {
          const dist = data?.data?.distributions;
          setLastDistributionsList(dist[dist.length - 1]);
        })
        .catch((err) => dispatch(handleError(err)))
        .finally(() => setLoading(false));
    };
    setLoading(true);
    fetchData();
  }, []);

  const handleChange = (e) => {
    setDistribution((prev) => ({
      ...prev,
      [e.target.name]: e.target.rawValue ?? e.target.value,
    }));
    setMyDistribution((prev) => ({
      ...prev,
      [e.target.name]: e.target.rawValue ?? e.target.value,
    }));
  };

  const { netDistribution } = distribution;
  const { amount, fees, retentionTax } = myDistribution;

  useEffect(() => {
    console.log(`Set ItemData ${index} ${investorItem.associateRef._id} `);
    if (itemfileData === undefined || itemfileData == null) {
      return;
    }
    setLocalFile(itemfileData);
  }, [itemfileData]);

  useEffect(() => {
    if (localFile == null || localFile === undefined) {
      return;
    }
    setDistribution((prevState) => ({
      ...prevState,
      amount: localFile?.amount ?? "0",
      fees: localFile?.fees ?? "0",
      retentionTax: localFile?.retentionTax ?? "0",
      netDistribution:
        Number(localFile?.amount ?? "0") -
        Number(localFile?.fees ?? "0") -
        Number(localFile?.retentionTax ?? "0"),
      date: localFile?.date ?? new Date().toISOString().slice(0, 10),
      period: localFile?.period ?? "",
    }));
    setMyDistribution((prevState) => ({
      ...prevState,
      amount: localFile?.amount ?? "0",
      fees: localFile?.fees ?? "0",
      retentionTax: localFile?.retentionTax ?? "0",
    }));

    if (!!localFile?.amount) {
      setReady(true);
    }
  }, [localFile]);

  useEffect(() => {
    setDistribution((prevState) => ({
      ...prevState,
      netDistribution: Number(amount) - Number(fees) - Number(retentionTax),
    }));
  }, [amount, fees, retentionTax]);

  useEffect(() => {
    const lastAccumulatedDistribution =
      lastDistributionsList?.accumulatedDistribution ?? "0";

    setDistribution((prevState) => ({
      ...prevState,
      accumulatedDistribution:
        Number(amount) + Number(lastAccumulatedDistribution),
    }));
  }, [amount, lastDistributionsList]);

  useEffect(() => {
    // console.log("Fess Acumulada");

    const lastAccumulatedFess = lastDistributionsList?.accumulatedFess ?? "0";

    setDistribution((prevState) => ({
      ...prevState,
      accumulatedFess: Number(fees) + Number(lastAccumulatedFess),
    }));
  }, [fees, lastDistributionsList]);

  useEffect(() => {
    // console.log("Retención Acumulada");
    const lastRetention = lastDistributionsList?.retention ?? "0";
    setDistribution((prevState) => ({
      ...prevState,
      retention: Number(retentionTax) + Number(lastRetention),
    }));
  }, [retentionTax, lastDistributionsList]);

  useEffect(() => {
    // console.log("Distribución neta acumulada");
    const lastNetAccumulated = lastDistributionsList?.netAccumulated ?? "0";
    setDistribution((prevState) => ({
      ...prevState,
      netAccumulated: Number(netDistribution) + Number(lastNetAccumulated),
    }));
  }, [netDistribution, lastDistributionsList]);

  const handleFinish = () => {
    setLoading(false);
    setMasterLoading((prev) => prev - 1);
    console.log("Finish");
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    const associateRef = investorItem.associateRef._id;
    const projectRef = investorItem.projectRef._id;
    if (loading) {
      console.log("HandleSubmit - Loading");
      return;
    }
    dispatch(
      createNewDistributionByInvestor({
        id: associateRef,
        distribution: {
          ...distribution,
          projectRef: projectRef,
          investmentRef: investorItem._id,
        },
        callback: handleFinish,
      })
    );
  };

  useEffect(() => {
    if (action) {
      if (!isReady) {
        if (loading) {
          console.log("HandleSubmit - Loading");
          return;
        }
        setMasterLoading((prev) => prev - 1);
        return;
      }
      setLoading(true);
      handleSubmit();
    }
  }, [action]);

  const handleCheckChange = (event) => {
    setReady(event.target.checked);
  };

  //   console.log({ fileData });
  return (
    <Card elevation={1} sx={{ px: 1, py: 1.5, my: 1 }}>
      <Grid container>
        <Grid item xs={8} md={10}>
          <Typography variant="body1" component={"p"} fontWeight={"bold"}>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to={`/dashboard/asociados/${investorItem.associateRef?._id}/inversion/${investorItem._id}`}
            >
              {`${investorItem?.associateRef?.name ?? ""}   ==>   ${
                localFile?.name ?? ""
              }`}
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={4} md={2}>
          <FormControlLabel
            control={<Switch checked={isReady} onChange={handleCheckChange} />}
            label={isReady ? "Preparado" : "No dispersar"}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent={"center"} spacing={0.5}>
        <Grid
          item
          xs={12}
          md={1}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          alignContent={"center"}
        >
          <Grid container>
            <Grid item xs={6}>
              <Typography
                variant="body1"
                component={"p"}
                fontWeight={"bold"}
                textAlign={"center"}
              >
                {index ?? 0}
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign={"center"}>
              {fileData.length === 0 ? (
                <HelpIcon color="secondary" />
              ) : (
                <div>
                  {!localFile ? (
                    <Cancel color="error" />
                  ) : (
                    <CheckCircle color="success" />
                  )}
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={10}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          alignContent={"center"}
        >
          <Grid
            container
            columnSpacing={2}
            rowSpacing={0.5}
            justifyContent={"center"}
          >
            <Grid item xs={6} sm={2}>
              <TextField
                type="date"
                label="Fecha"
                name="date"
                value={distribution.date}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6} sm={2}>
              <TextField
                label="Periodo"
                name="period"
                value={distribution.period}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6} sm={2}>
              <CurrencyField
                label="Monto"
                name="amount"
                value={distribution.amount}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6} sm={2}>
              <CurrencyField
                label="Fees"
                name="fees"
                value={distribution.fees}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6} sm={2}>
              <CurrencyField
                label="Retención de impuestos"
                name="retentionTax"
                value={distribution.retentionTax}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6} sm={2}>
              <CurrencyField
                disabled
                label="Distribución neta"
                name="netDistribution"
                value={distribution.netDistribution}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={0} sm={2}></Grid>
            <Grid item xs={0} sm={2}></Grid>
            <Grid item xs={6} sm={2}>
              <CurrencyField
                disabled
                label="Distribución acumulada"
                name="accumulatedDistribution"
                value={distribution.accumulatedDistribution}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6} sm={2}>
              <CurrencyField
                disabled
                label="Fees acumulados"
                name="accumulatedFess"
                value={distribution.accumulatedFess}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6} sm={2}>
              <CurrencyField
                disabled
                label="Retenciones acumuladas"
                name="retention"
                value={distribution.retention}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6} sm={2}>
              <CurrencyField
                disabled
                label="Neto acumulado"
                name="netAccumulated"
                value={distribution.netAccumulated}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={1}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          alignContent={"center"}
        >
          {loading && <CircularProgress />}
        </Grid>
      </Grid>
    </Card>
  );
}

export default ProjectInvestorItem;
