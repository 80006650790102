import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: [],
  current: {}
}

const loansSlice = createSlice({
  name: 'loans',
  initialState,
  reducers: {
    setLoansList: (state, { payload }) => ({ ...state, list: [...payload.loans] }),
    setCurrentLoan: (state, { payload }) => ({ ...state, current: { ...payload.loan } }),
    resetLoansState: () => ({ list: [], current: { } })
  }
});

export const { setLoansList, setCurrentLoan, resetLoansState } = loansSlice.actions;
export default loansSlice.reducer;
