import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
  current: {
    associateRef: "",
    projectRef: "",
    credit: "",
  },
};

const associateSlice = createSlice({
  name: "associate",
  initialState,
  reducers: {
    setAssociateList: (state, { payload }) => ({
      ...state,
      list: [...payload.associates],
    }),
    setCurrentAssociate: (state, { payload }) => ({
      ...state,
      current: payload.investment,
    }),
    resetAssociateState: () => ({ list: [], current: {} }),
  },
});

export const { setAssociateList, setCurrentAssociate, resetAssociateState } =
  associateSlice.actions;
export default associateSlice.reducer;
