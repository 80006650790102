const stringDiacriticInsensitive = (s) => {
  return s
    .replace(/[aáà]/gi, "a")
    .replace(/[eéè]/gi, "e")
    .replace(/[iíì]/gi, "i")
    .replace(/[oóò]/gi, "o")
    .replace(/[uúù]/gi, "u")
    .toLowerCase()
    .trim();
};

export default stringDiacriticInsensitive;
