import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createProject,
  createProjectInterest,
  deleteProject,
  deleteProjectImage,
  getProject,
  getProjectInterests,
  getProjects,
  updateProject,
  uploadProjectDescriptionImage,
  uploadProjectImage,
} from "../api/projectsApi";
import { showAlert } from "../slices/alertsSlice";
import { setCurrentProject, setProjectsList } from "../slices/projectsSlice";
import { setInterestsList } from "../slices/interestsSlice";
import { handleError } from "../slices/errorsSlice";
import { startLoading, endLoading } from "../slices/loadingSlice";
import { setProjectsAssociateList } from "../slices/projectAssociatesSlice";
import { requestInvestments } from "../api/request/requestInvestments";

export const getProjectsList = createAsyncThunk(
  "projects/getProjectsList",
  ({ query }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    getProjects(token, query)
      .then((response) =>
        response.status === 200 ? response.json() : Promise.reject(response)
      )
      .then((data) => dispatch(setProjectsList({ projects: data.projects })))
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const getProjectsListWithAssociateList = createAsyncThunk(
  "projects/getProjectsList",
  ({ query }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    getProjects(token, query)
      .then((response) =>
        response.status === 200 ? response.json() : Promise.reject(response)
      )
      .then((data) => {
        dispatch(setProjectsList({ projects: data.projects }));
        return data.projects;
      })
      .then((projects) => {
        let projectAssociate = [];
        projects?.forEach((element) => {
          const result = requestInvestments(
            token,
            (query = { projectRef: element._id, results: 999999 })
          );
          projectAssociate.push(result);
        });
        return projectAssociate;
      })
      .then(async (result) => {
        const data = await Promise.allSettled(result);
        return data;
      })
      .then((result) => {
        const myList = result?.map((data) => ({
          associates: data.value?.investments ?? [],
          projectRef: data.value?.investments[0].projectRef?._id ?? "",
        }));
        dispatch(setProjectsAssociateList({ projects: myList }));
      })
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const getCurrentProject = createAsyncThunk(
  "projects/getCurrentProject",
  ({ id }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    getProject(token, id)
      .then((response) =>
        response.status === 200 ? response.json() : Promise.reject(response)
      )
      .then((data) => dispatch(setCurrentProject({ project: data.project })))
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const createNewProject = createAsyncThunk(
  "projects/createNewProject",
  ({ project, callback }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    createProject(token, project)
      .then((response) =>
        response.status === 201 ? response.json() : Promise.reject(response)
      )
      .then((data) => callback(data.project_id))
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const updateCurrentProject = createAsyncThunk(
  "projects/updateCurrentProject",
  ({ id, project }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    updateProject(token, id, project)
      .then((response) =>
        response.status === 200 ? response.json() : Promise.reject(response)
      )
      .then((data) => dispatch(setCurrentProject({ project: data.project })))
      .then(() =>
        dispatch(
          showAlert({ message: "Proyecto actualizado", type: "success" })
        )
      )
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const getCurrentProjectInterests = createAsyncThunk(
  "projects/getCurrentProjectInterests",
  ({ id }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    getProjectInterests(token, id)
      .then((response) =>
        response.status === 200 ? response.json() : Promise.reject(response)
      )
      .then((data) => dispatch(setInterestsList({ interests: data.payments })))
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const createNewProjectInterest = createAsyncThunk(
  "projects/createNewProjectInterest",
  ({ id, interest, callback }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    createProjectInterest(token, id, interest)
      .then((response) =>
        response.status === 201 ? response.json() : Promise.reject(response)
      )
      .then((data) => callback(data))
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const uploadCurrentProjectImage = createAsyncThunk(
  "projects/uploadCurrentProjectImage",
  ({ id, file }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    uploadProjectImage(token, id, file)
      .then((response) =>
        response.status === 200 ? response.json() : Promise.reject(response)
      )
      .then(() => dispatch(getCurrentProject({ id })))
      .then(() =>
        dispatch(showAlert({ message: "Imagen subida", type: "success" }))
      )
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const uploadCurrentProjectDescriptionImage = createAsyncThunk(
  "projects/uploadCurrentProjectDescriptionImage",
  ({ id, file }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    uploadProjectDescriptionImage(token, id, file)
      .then((response) =>
        response.status === 200 ? response.json() : Promise.reject(response)
      )
      .then(() => dispatch(getCurrentProject({ id })))
      .then(() =>
        dispatch(showAlert({ message: "Imagen subida", type: "success" }))
      )
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const deleteCurrentProject = createAsyncThunk(
  "projects/deleteCurrentProject",
  ({ id, callback }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    deleteProject(token, id)
      .then((response) =>
        response.status === 200 ? true : Promise.reject(response)
      )
      .then(() => callback())
      .then(() =>
        dispatch(showAlert({ message: "Proyecto borrado", type: "success" }))
      )
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const deleteCurrentProjectImage = createAsyncThunk(
  "projects/deleteCurrentProjectImage",
  ({ id, imageId }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    deleteProjectImage(token, id, imageId)
      .then((response) =>
        response.status === 200 ? true : Promise.reject(response)
      )
      .then(() => dispatch(getCurrentProject({ id })))
      .then(() =>
        dispatch(showAlert({ message: "Imagen borrado", type: "success" }))
      )
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);
