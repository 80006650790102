import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Check, Delete } from '@mui/icons-material';
import { TableRow, TableCell, IconButton, TextField, MenuItem } from '@mui/material';
import { useState } from 'react';
import CurrencyField from '../inputs/CurrencyField';
import PercentageField from '../inputs/PercentageField';

import { deleteCurrentFee, updateCurrentFee } from '../../actions/feesActions';

const ProjectDetailsFeeItem = props => {
  const dispatch = useDispatch();

  const params = useParams();

  const [data, setData] = useState({
    name: props.name ?? '',
    value: props.value ?? 0,
    applyType: props.applyType ?? '',
    type: props.type ?? '',
  });

  const handleChange = e => setData(prev => ({ ...prev, [e.target.name]: e.target.rawValue ?? e.target.value }));

  const handleSave = e => {
    e?.preventDefault();
    dispatch(updateCurrentFee({ id: props._id, fee: data }));
  }

  const handleDelete = e => {
    e?.preventDefault();
    dispatch(deleteCurrentFee({ id: props._id, query: { projectRef: params.id } }));
  }

  return (
    <TableRow>
      <TableCell><TextField label="Nombre" name="name" value={data.name} onChange={handleChange} /></TableCell>
      <TableCell>
        <TextField select label="Tipo" name="type" value={data.type} onChange={handleChange}>
          <MenuItem value="distribution">Distribución</MenuItem>
          <MenuItem value="payment">Interés</MenuItem>
        </TextField>
      </TableCell>
      <TableCell>
        <TextField select label="Aplicación" name="applyType" value={data.applyType} onChange={handleChange}>
          <MenuItem value="monto">Monto</MenuItem>
          <MenuItem value="porcentaje">Porcentaje</MenuItem>
        </TextField>
      </TableCell>
      <TableCell>
        {data.applyType === 'monto'
          ? <CurrencyField label="Valor" name="value" value={data.value} onChange={handleChange} />
          : <PercentageField label="Valor" name="value" value={data.value} onChange={handleChange} />
        }
      </TableCell>
      <TableCell><IconButton onClick={handleSave}><Check /></IconButton></TableCell>
      <TableCell><IconButton onClick={handleDelete}><Delete /></IconButton></TableCell>
    </TableRow>
  );
}

export default ProjectDetailsFeeItem;
