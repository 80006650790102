import { Card, Grid, IconButton, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import CurrencyField from "../../../../../../components/inputs/CurrencyField";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCurrentDistribution,
  getDistributionsList,
  updateCurrentDistribution,
} from "../../../../../../actions/distributionsActions";
import { useParams } from "react-router-dom";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import InvestorProjectDetailsReports from "../InvestorProjectDetailsReports";
import DeleteConfirmationDialog from "./DeleteConfirmModal";
import FilePresentIcon from "@mui/icons-material/FilePresent";
// import { deleteCurrentReport } from "../../../../../../actions/reportsActions";
import {
  AttachFileOutlined,
  DeleteForever,
  Edit,
  Save,
} from "@mui/icons-material";

const { REACT_APP_HOST_URL } = process.env;

function DitributionsItem({ distribution, report, lastDistribution }) {
  let { investmentId } = useParams();
  const dispatch = useDispatch();
  const current = useSelector((state) => state.investments.current);

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [edit, setEdit] = useState(true);

  const [localDistribution, setDistribution] = useState({
    ...distribution,
    date: new Date(distribution.date).toISOString().slice(0, 10),
  });

  const handleChange = (e) => {
    // console.log(e.target.name);
    if (e.target.name === "isUSD" || e.target.name === "isInvestmentUSD") {
      setDistribution({
        ...localDistribution,
        [e.target.name]: e.target.checked,
      });
    } else {
      setDistribution({
        ...localDistribution,
        [e.target.name]: e.target.rawValue ?? e.target.value,
      });
    }
  };

  const handleSave = (e) => {
    e?.preventDefault();
    dispatch(
      updateCurrentDistribution({
        id: distribution._id,
        distribution: localDistribution,
        callback: () => {
          setEdit(!edit);
          const associateRef = current[0]?.associateRef._id;
          const projectRef = current[0]?.projectRef._id;
          dispatch(
            getDistributionsList({
              query: {
                associateRef,
                projectRef,
                associateInvestmentId: investmentId,
              },
            })
          );
        },
      })
    );
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };

  const handleDelete = (e) => {
    e?.preventDefault();
    dispatch(
      deleteCurrentDistribution({
        id: distribution._id,
        query: {
          projectRef: distribution.projectRef,
          associateRef: distribution.associateRef,
          associateInvestmentId: investmentId,
        },
      })
    );
  };

  const [reports, setReports] = useState(false);
  const handleOpenReports = () => setReports(true);
  const handleCloseReports = () => setReports(false);

  // const handleReportDelete = (e) => {
  //   e?.preventDefault();
  //   const query = { projectRef: report.projectRef };
  //   report.associateRef ? (query.associateRef = report.associateRef) : null;
  //   // filter ? (query.type = filter) : null;
  //   dispatch(
  //     deleteCurrentReport({
  //       id: report._id,
  //       query: { ...query, associateInvestmentId: investmentId },
  //     })
  //   );
  // };

  const { amount, fees, retentionTax, netDistribution } = localDistribution;

  useEffect(() => {
    if (edit) {
      return;
    }
    setDistribution((prevState) => ({
      ...prevState,
      netDistribution: Number(amount) - Number(fees) - Number(retentionTax),
    }));
  }, [amount, fees, retentionTax, edit]);

  useEffect(() => {
    if (edit) {
      return;
    }
    setDistribution((prevState) => ({
      ...prevState,
      accumulatedDistribution:
        Number(amount) +
        Number(lastDistribution?.accumulatedDistribution ?? "0"),
    }));
  }, [amount, lastDistribution, edit]);

  useEffect(() => {
    if (edit) {
      return;
    }
    setDistribution((prevState) => ({
      ...prevState,
      accumulatedFess:
        Number(fees) + Number(lastDistribution?.accumulatedFess ?? "0"),
    }));
  }, [fees, lastDistribution, edit]);

  useEffect(() => {
    if (edit) {
      return;
    }
    console.log("Retención Acumulada");
    setDistribution((prevState) => ({
      ...prevState,
      retention:
        Number(retentionTax) + Number(lastDistribution?.retention ?? "0"),
    }));
  }, [retentionTax, lastDistribution, edit]);

  useEffect(() => {
    if (edit) {
      return;
    }
    setDistribution((prevState) => ({
      ...prevState,
      netAccumulated:
        Number(netDistribution) +
        Number(lastDistribution?.netAccumulated ?? "0"),
    }));
  }, [netDistribution, lastDistribution, edit]);
  return (
    <>
      <InvestorProjectDetailsReports
        open={reports}
        onClose={handleCloseReports}
        myReports={report}
        distributionId={distribution._id}
        localPeriod={localDistribution.period}
        localDate={localDistribution.date}
      />
      <DeleteConfirmationDialog
        open={deleteOpen}
        title={"Deseas borrar distribución"}
        message={"Esta acción es permanente, ¿estas seguro de continuar?"}
        onClose={handleDeleteClose}
        onConfirm={handleDelete}
      ></DeleteConfirmationDialog>
      <Card elevation={3} sx={{ my: 0.5, px: 1, py: 2 }}>
        {/* <Grid container>
          <Grid item xs={6} md={3} lg={2}>
            <Typography
              variant="p"
              component={"p"}
              fontWeight={"bold"}
              color={"white"}
            >
              {localDistribution.date}
            </Typography>
          </Grid>
          <Grid item xs={6} md={3} lg={2}>
            <Typography
              variant="p"
              component={"p"}
              fontWeight={"bold"}
              color={"white"}
            >
              {localDistribution.period}
            </Typography>
          </Grid>
        </Grid> */}
        <Grid container columnSpacing={0} rowSpacing={2}>
          <Grid
            item
            xs={12}
            md={11}
            lg={11}
            textAlign={"center"}
            alignItems={"center"}
            alignContent={"center"}
          >
            <Grid container spacing={2}>
              <Grid
                item
                sx={{
                  maxWidth: {
                    xs: "calc(100%/2)",
                    sm: "33%",
                    md: "14%",
                    lg: "14%",
                  },
                }}
              >
                <TextField
                  disabled={edit}
                  type="date"
                  label="Fecha"
                  name="date"
                  value={localDistribution.date}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid
                item
                sx={{
                  maxWidth: {
                    xs: "calc(100%/2)",
                    sm: "33%",
                    md: "9.5%",
                    lg: "9.5%",
                  },
                }}
              >
                <TextField
                  disabled={edit}
                  type="text"
                  label="Periodo"
                  name="period"
                  value={localDistribution.period}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid
                item
                sx={{
                  maxWidth: {
                    xs: "calc(100%/2)",
                    sm: "33%",
                    md: "9.5%",
                    lg: "9.5%",
                  },
                }}
              >
                <CurrencyField
                  disabled={edit}
                  type="text"
                  label="Monto"
                  name="amount"
                  value={amount}
                  onChange={handleChange}
                />
              </Grid>
              <Grid
                item
                sx={{
                  maxWidth: {
                    xs: "calc(100%/2)",
                    sm: "33%",
                    md: "9.5%",
                    lg: "9.5%",
                  },
                }}
              >
                <CurrencyField
                  disabled={edit}
                  type="text"
                  label="Fees"
                  name="fees"
                  value={fees}
                  onChange={handleChange}
                />
              </Grid>
              <Grid
                item
                sx={{
                  maxWidth: {
                    xs: "calc(100%/2)",
                    sm: "33%",
                    md: "9.5%",
                    lg: "9.5%",
                  },
                }}
              >
                <CurrencyField
                  disabled={edit}
                  type="text"
                  label="Retención impto"
                  name="retentionTax"
                  value={retentionTax}
                  onChange={handleChange}
                />
              </Grid>
              <Grid
                item
                sx={{
                  maxWidth: {
                    xs: "calc(100%/2)",
                    sm: "33%",
                    md: "9.5%",
                    lg: "9.5%",
                  },
                }}
              >
                <CurrencyField
                  disabled
                  type="text"
                  label="Distribución neta"
                  name="netDistribution"
                  value={netDistribution}
                  onChange={handleChange}
                />
              </Grid>
              <Grid
                item
                sx={{
                  maxWidth: {
                    xs: "calc(100%/2)",
                    sm: "33%",
                    md: "9.5%",
                    lg: "9.5%",
                  },
                }}
              >
                <CurrencyField
                  disabled
                  type="text"
                  label="Distribucion acum."
                  name="accumulatedDistribution"
                  value={localDistribution.accumulatedDistribution}
                  onChange={handleChange}
                />
              </Grid>
              <Grid
                item
                sx={{
                  maxWidth: {
                    xs: "calc(100%/2)",
                    sm: "33%",
                    md: "9.5%",
                    lg: "9.5%",
                  },
                }}
              >
                <CurrencyField
                  disabled
                  type="text"
                  label="Fees acum."
                  name="accumulatedFess"
                  value={localDistribution.accumulatedFess}
                  onChange={handleChange}
                />
              </Grid>
              <Grid
                item
                sx={{
                  maxWidth: {
                    xs: "calc(100%/2)",
                    sm: "33%",
                    md: "9.5%",
                    lg: "9.5%",
                  },
                }}
              >
                <CurrencyField
                  disabled
                  type="text"
                  label="Retencion acum."
                  name="retention"
                  value={localDistribution.retention}
                  onChange={handleChange}
                />
              </Grid>
              <Grid
                item
                sx={{
                  maxWidth: {
                    xs: "calc(100%/2)",
                    sm: "33%",
                    md: "9.5%",
                    lg: "9.5%",
                  },
                }}
              >
                <CurrencyField
                  disabled
                  type="text"
                  label="Acumulado Neto"
                  name="netAccumulated"
                  value={localDistribution.netAccumulated}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={1} lg={1}>
            {/* {report != null ? (
                <>
                  <Grid item xs={6} md={12} textAlign={"center"}>
                    <Box
                      display={"flex"}
                      alignContent={"center"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Box flex={1}>
                        <a
                          href={`${REACT_APP_HOST_URL}/images/reports/${report.path}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {report.name}
                        </a>
                      </Box>
                      <IconButton sx={{ p: 0.5 }} onClick={handleReportDelete}>
                        <Delete fontSize="sm" />
                      </IconButton>
                    </Box>
                  </Grid>
                </>
              ) : (
                <Grid item xs={6} md={12} textAlign={"center"}>
                  <Typography variant="caption" component={"p"} mt={1}>
                    Sin reporte
                  </Typography>
                </Grid>
              )} */}
            <Grid container justifyContent={"space-between"}>
              {report != null ? (
                <Grid item xs={3} sm={3} md={6} lg={3}>
                  {!edit ? (
                    <IconButton
                      size={"small"}
                      color="info"
                      onClick={handleOpenReports}
                    >
                      <FilePresentIcon />
                    </IconButton>
                  ) : (
                    <a
                      href={`${REACT_APP_HOST_URL}/images/reports/${report.path}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <IconButton size={"small"} color="info">
                        <AttachFileOutlined></AttachFileOutlined>
                      </IconButton>
                    </a>
                  )}
                </Grid>
              ) : (
                <Grid item xs={3} sm={3} md={6} lg={3}>
                  <IconButton
                    size={"small"}
                    color="info"
                    onClick={handleOpenReports}
                  >
                    <FileUploadIcon></FileUploadIcon>
                  </IconButton>
                </Grid>
              )}

              <Grid item xs={3} sm={3} md={6} lg={3}>
                {edit ? (
                  <IconButton
                    onClick={() => {
                      setEdit(!edit);
                    }}
                  >
                    <Edit></Edit>
                  </IconButton>
                ) : (
                  <IconButton
                    size={"small"}
                    color="primary"
                    onClick={handleSave}
                  >
                    <Save></Save>
                  </IconButton>
                )}
              </Grid>
              <Grid item xs={3} sm={3} md={6} lg={3}>
                <IconButton
                  size={"small"}
                  color="error"
                  onClick={handleDeleteOpen}
                >
                  <DeleteForever></DeleteForever>
                </IconButton>
              </Grid>
            </Grid>
            {/* <Grid item xs={6} md={12}>
              <Button fullWidth onClick={handleOpenReports} sx={{ my: 0 }}>
                Reportes
              </Button>
            </Grid>
            <Grid item xs={6} md={12}>
              <Button
                variant="outlined"
                fullWidth
                sx={{ my: 0 }}
                onClick={handleSave}
              >
                Guardar
              </Button>
            </Grid>
            <Grid item xs={6} md={12}>
              <Button
                color="error"
                fullWidth
                sx={{ my: 0 }}
                onClick={handleDeleteOpen}
              >
                Borrar
              </Button>
            </Grid> */}
          </Grid>
        </Grid>
      </Card>
    </>
  );
}

export default DitributionsItem;
