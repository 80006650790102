import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Close } from '@mui/icons-material';
import { Box, Dialog, DialogContent, DialogTitle, Button, DialogActions, Typography, IconButton, Divider, Grid, TextField } from '@mui/material';

import { updateCurrentProject } from '../../../../actions/projectsActions';

const styles = {
  container: {
    display: 'block',
  },
  content: {
    paddingY: 0,
    width: {
      md: '60vw'
    }
  },
  actions: {
    paddingRight: '1rem'
  },
  title: {
    my: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  fieldWrapper: {
    px: '1rem'
  },
  image: {
    height: '350px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  }
}

const ProjectDetailsDescriptionDialog = props => {
  const dispatch = useDispatch();
  const params = useParams();
  const [description, setDescription] = useState(props.description);

  const handleChange = e => setDescription(e.target.value);

  useEffect(() => setDescription(props.description), [props.description]);

  const handleClose = e => {
    e?.preventDefault();
    props.onClose();
  }

  const handleSubmit = e => {
    e?.preventDefault();
    dispatch(updateCurrentProject({ id: params.id, project: { description } }));
  }

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
    >
      <DialogTitle>
       <Box sx={styles.title}>
          <Typography variant="h5">Descripción</Typography>
          <IconButton onClick={handleClose}><Close /></IconButton>
        </Box>
        <Divider />
      </DialogTitle>
      <DialogContent sx={styles.content}>
        <Grid container>
          <Grid item xs={12}>
            <TextField multiline rows={5} label="Descripción" name="description" value={description} onChange={handleChange} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <Button onClick={handleSubmit}>Guardar</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ProjectDetailsDescriptionDialog;
