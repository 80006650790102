import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@mui/material";
import InvestorsListItem from "./InvestorsListItem";

const InvestorsListTable = (props) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead color="primary">
          <TableRow>
            <TableCell>Nombre</TableCell>
            <TableCell>Contacto</TableCell>
            <TableCell>Proyecto</TableCell>
            <TableCell>Fecha de inversión</TableCell>
            <TableCell>Inversión en el proyecto</TableCell>
            <TableCell>Borrar</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.investors?.map((investor) => (
            <InvestorsListItem
              key={investor._id}
              {...investor}
              onDelete={props.onDelete}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InvestorsListTable;
