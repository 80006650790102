const { REACT_APP_API_BASE_URL } = process.env;

export const getInvestors = (token, query) => new Promise((resolve, reject) => {
  const queryParams = new URLSearchParams({
    results: 10,
    ...query
  }).toString();
  const request = new Request(REACT_APP_API_BASE_URL + '/associates?' + queryParams, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const getInvestor = (token, id) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/associates/' + id, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const createInvestor = (token, investor) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/associates', {
    method: 'POST',
    body: JSON.stringify({ associate: investor }),
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const updateInvestor = (token, id, investor) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/associates/' + id, {
    method: 'PUT',
    body: JSON.stringify({ associate: investor }),
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const createInvestorDistribution = (token, id, distribution) => new Promise((resolve, reject) => {
  const request = new Request(`${REACT_APP_API_BASE_URL}/associates/${id}/distributions`, {
    method: 'POST',
    body: JSON.stringify({ distribution }),
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const createInvestorInterest = (token, id, interest) => new Promise((resolve, reject) => {
  const request = new Request(`${REACT_APP_API_BASE_URL}/associates/${id}/payments`, {
    method: 'POST',
    body: JSON.stringify({ payments: interest }),
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const deleteInvestor = (token, id) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/associates/' + id, {
    method: 'DELETE',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});
