import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

import InvestorProjectDetailsInterestCreate from './InvestorProjectDetailsInterestCreate';
import InvestmentInterestItem from '../../../../../components/items/InvestmentInterestItem';

const styles = {
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}

const InvestorProjectDetailsInterests = () => {
  const interests = useSelector(state => state.interests.list);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Box sx={styles.title}>
        <Typography variant="h5">Pago de intereses</Typography>
        <Button onClick={handleOpen}>Añadir</Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Fecha</TableCell>
              <TableCell>Periodo</TableCell>
              <TableCell>Monto</TableCell>
              <TableCell>Fees</TableCell>
              <TableCell>Distribución neta</TableCell>
              <TableCell>Pago en MXN</TableCell>
              <TableCell sx={{ borderRight: '2px solid #EEE' }}>Tipo de cambio</TableCell>
              <TableCell>Distribuciones acumuladas</TableCell>  
              <TableCell>Fees acumulados</TableCell>
              <TableCell>Retenciones acumuladas</TableCell>
              <TableCell>Acumulado Neto</TableCell>
              <TableCell>Guardar</TableCell>
              <TableCell>Borrar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {interests.map(interest => <InvestmentInterestItem key={interest._id} {...interest} />)}
          </TableBody>
        </Table>
      </TableContainer>
      <InvestorProjectDetailsInterestCreate open={open} onClose={handleClose} />
    </>
  );
}

export default InvestorProjectDetailsInterests;
