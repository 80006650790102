import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Image } from '@mui/icons-material';
import { Box, Fab } from '@mui/material';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

import ProjectDetailsImagesModal from './ProjectDetailsImagesModal';

const { REACT_APP_HOST_URL } = process.env;

const styles = {
  carousel: {
    height: '350px'
  },
  slide: {
    height: '350px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  },
  fab: {
    position: 'absolute',
    padding: '0.5rem',
    right: 0
  }
}

const ProjectDetailsImagesCarousel = () => {
  const [open, setOpen] = useState(false);
  const images = useSelector(state => state.projects.current?.images);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={styles.fab}>
        <Fab onClick={handleOpen} size="small" color="primary">
          <Image />
        </Fab>
      </Box>
      <Splide options={ { rewind: true } } style={styles.carousel}>
        {images?.length > 0 ? images.map(image =>
          <SplideSlide key={image._id}>
            <Box sx={{ ...styles.slide, backgroundImage: `url(${REACT_APP_HOST_URL}/images/projects/${image.path})` }} />
          </SplideSlide>  
        ) : 
        <SplideSlide>
            <Box sx={{ ...styles.slide, backgroundImage: `url(/img/img-1.jpg)` }} />
        </SplideSlide>  
        }
      </Splide>
      <ProjectDetailsImagesModal open={open} onClose={handleClose} images={images} />
    </Box>
  );
}

export default ProjectDetailsImagesCarousel;
