import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";

import ProjectDetailsInfo from "./ProjectDetailsInfo";
import ProjectDetailsDescription from "./ProjectDetailsDescription";
import ProjectDetailsDistributions from "./ProjectDetailsDistributions";
import ProjectDetailsInvetsments from "./ProjectDetailsInvestments";
import ProjectDetailsInterests from "./ProjectDetailsInterests";
import ProjectDetailsPartials from "./ProjectDetailsPartials";
import ProjectDetailsLoans from "./ProjectDetailsLoans";
import ProjectDetailsFees from "./ProjectDetailsFees";
import ProjectDetailsReports from "./ProjectDetailsReports";

import {
  getCurrentProject,
  getCurrentProjectInterests,
  updateCurrentProject,
} from "../../../../actions/projectsActions";
import { resetProjectsState } from "../../../../slices/projectsSlice";
import { getDistributionsList } from "../../../../actions/distributionsActions";
import { getInvestmentsList } from "../../../../actions/investmentsActions";
import { getPartialsList } from "../../../../actions/partialsActions";
import { getLoansList } from "../../../../actions/loansActions";
import { getFeesList } from "../../../../actions/feesActions";
import { getReportsList } from "../../../../actions/reportsActions";
import ProjectDetailsFinanceInfo from "./ProjectDetailsFinanceInfo";

const styles = {
  columns: {
    px: "1rem",
  },
};

const ProjectDetails = () => {
  // const navigate = useNavigate();
  const params = useParams();

  const [project, setProject] = useState({
    _id: "",
    name: "",
    alias: "",
    order: "",
    investmentDate: "",
    investment: "",
    percentageIde: "",
    performance: "",
    contributions: "",
    provisions: "",
    vehiculo: "",
    isUSD: false,
    isInvestmentUSD: false,
  });

  const [modals, setModals] = useState({
    distributions: false,
    investments: false,
    interests: false,
    partials: false,
    loans: false,
    fees: false,
    reports: false,
    finance: false,
  });

  useEffect(() => {
    dispatch(getCurrentProject({ id: params.id }));
    dispatch(getCurrentProjectInterests({ id: params.id }));
    dispatch(getDistributionsList({ query: { projectRef: params.id } }));
    dispatch(
      getInvestmentsList({ query: { projectRef: params.id, results: 999999 } })
    );
    dispatch(getPartialsList({ query: { projectRef: params.id } }));
    dispatch(getLoansList({ query: { projectRef: params.id } }));
    dispatch(getFeesList({ query: { projectRef: params.id } }));
    dispatch(getReportsList({ query: { projectRef: params.id } }));
    return () => dispatch(resetProjectsState());
  }, []);

  const dispatch = useDispatch();
  const current = useSelector((state) => state.projects.current);

  useEffect(
    () =>
      setProject({
        ...project,
        ...current,
        investmentDate: current.investmentDate?.slice(0, 10) ?? "",
      }),
    [current]
  );

  const handleChange = (e) => {
    if (e.target.name === "isUSD" || e.target.name === "isInvestmentUSD") {
      setProject({ ...project, [e.target.name]: e.target.checked });
    } else {
      setProject({
        ...project,
        [e.target.name]: e.target.rawValue ?? e.target.value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const {
      name,
      alias,
      // order,
      investmentDate,
      investment,
      percentageIde,
      performance,
      contributions,
      provisions,
      vehiculo,
      isUSD,
      isInvestmentUSD,
    } = project;

    dispatch(
      updateCurrentProject({
        id: params.id,
        project: {
          name,
          alias,
          // order: Number(order),
          investmentDate,
          investment,
          percentageIde,
          performance,
          contributions,
          provisions,
          vehiculo,
          isUSD,
          isInvestmentUSD,
        },
      })
    );
  };

  const handleOpenModal = (modal) => setModals({ ...modals, [modal]: true });
  const handleCloseModal = (modal) => setModals({ ...modals, [modal]: false });

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={6} md={8}>
          <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ marginBottom: "1rem" }}
          >
            Proyecto {project.name ?? params.id}
          </Typography>
        </Grid>
        {/* <Grid item xs={12} sm={6} md={4} sx={styles.columns}>
          <Button
            color="success"
            fullWidth
            onClick={() => {
              navigate(
                "/dashboard/proyectos/" + params.id + "/importar-distribuciones"
              );
            }}
          >
            Carga masiva Distribución
          </Button>
        </Grid> */}
      </Grid>
      <Grid container>
        <Grid item xs={12} md={5} sx={styles.columns}>
          <ProjectDetailsDescription {...project} onChange={handleChange} />
        </Grid>
        <Grid item xs={12} md={7} sx={styles.columns}>
          <ProjectDetailsInfo
            {...project}
            onChange={handleChange}
            onSubmit={handleSubmit}
            onOpen={handleOpenModal}
          />
        </Grid>
      </Grid>
      <ProjectDetailsDistributions
        open={modals.distributions}
        onClose={() => handleCloseModal("distributions")}
      />
      <ProjectDetailsInvetsments
        open={modals.investments}
        onClose={() => handleCloseModal("investments")}
      />
      <ProjectDetailsInterests
        open={modals.interests}
        onClose={() => handleCloseModal("interests")}
      />
      <ProjectDetailsPartials
        open={modals.partials}
        onClose={() => handleCloseModal("partials")}
      />
      <ProjectDetailsLoans
        open={modals.loans}
        onClose={() => handleCloseModal("loans")}
      />
      <ProjectDetailsFees
        open={modals.fees}
        onClose={() => handleCloseModal("fees")}
      />
      <ProjectDetailsReports
        open={modals.reports}
        onClose={() => handleCloseModal("reports")}
      />
      <ProjectDetailsFinanceInfo
        open={modals.finance}
        onClose={() => handleCloseModal("finance")}
      />
    </>
  );
};

export default ProjectDetails;
