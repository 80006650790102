import { useDispatch } from 'react-redux';
import { Delete } from '@mui/icons-material';
import { TableRow, TableCell, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { currencyFormatter } from '../../../utils/formatUtils';
import { deleteCurrentProject } from '../../../actions/projectsActions';

const ProjectsListItem = props => {
  const dispatch = useDispatch();

  const handleDelete = e => {
    e?.preventDefault();
    dispatch(deleteCurrentProject({ id: props._id, callback: () => props.onDelete() }));
  }

  return (
    <TableRow>
      <TableCell><Link to={props._id}>{props.name}</Link></TableCell>
      <TableCell>{new Date(`${props.investmentDate?.slice?.(0, 10)}T00:00:00`)?.toLocaleDateString()}</TableCell>
      <TableCell>{props.isInvestmentUSD ? 'USD':"MXP"}</TableCell>
      <TableCell>{currencyFormatter.format(props.investment)}</TableCell>
      <TableCell>{props.percentageIde ?? '-'}%</TableCell>
      <TableCell onClick={handleDelete}><IconButton><Delete /></IconButton></TableCell>
    </TableRow>
  );
}

export default ProjectsListItem;
