import { getInvestments } from "../investmentsApi";

export const requestInvestments = (token, query) =>
  new Promise((resolve, reject) => {
    getInvestments(token, query)
      .then((response) =>
        response.status === 200 ? response.json() : reject(response)
      )
      .then((data) => resolve(data));
  });
